import React from "react";

export default function TimeWatch() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="20"
      height="20"      
    >
      <g id="calendar-day-schedule-correct-event">
        <path d="M16,20H10a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V21A1,1,0,0,0,16,20Zm-1,6H11V22h4Z"></path>
        <path d="M26,20H20a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V21A1,1,0,0,0,26,20Zm-1,6H21V22h4Z"></path>
        <path d="M30,28h6a1,1,0,0,0,1-1V21a1,1,0,0,0-1-1H30a1,1,0,0,0-1,1v6A1,1,0,0,0,30,28Zm1-6h4v4H31Z"></path>
        <path d="M40,28h6a1,1,0,0,0,1-1V21a1,1,0,0,0-1-1H40a1,1,0,0,0-1,1v6A1,1,0,0,0,40,28Zm1-6h4v4H41Z"></path>
        <path d="M16,30H10a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V31A1,1,0,0,0,16,30Zm-1,6H11V32h4Z"></path>
        <path d="M26,30H20a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V31A1,1,0,0,0,26,30Zm-1,6H21V32h4Z"></path>
        <path d="M34.006,37a1,1,0,0,0-1-1H31V32h4v1.965a1,1,0,0,0,2,0V31a1,1,0,0,0-1-1H30a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h3.006A1,1,0,0,0,34.006,37Z"></path>
        <path d="M16,40H10a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V41A1,1,0,0,0,16,40Zm-1,6H11V42h4Z"></path>
        <path d="M26,40H20a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V41A1,1,0,0,0,26,40Zm-1,6H21V42h4Z"></path>
        <path d="M54,33.74V9a3.009,3.009,0,0,0-3-3H46V5a3,3,0,0,0-6,0V6H16V5a3,3,0,0,0-6,0V6H5A3.009,3.009,0,0,0,2,9V51a3.009,3.009,0,0,0,3,3H33.74A15,15,0,1,0,54,33.74ZM42,5a1,1,0,0,1,2,0V9a1,1,0,0,1-2,0ZM12,5a1,1,0,0,1,2,0V9a1,1,0,0,1-2,0ZM4,9A1,1,0,0,1,5,8h5V9a3,3,0,0,0,6,0V8H40V9a3,3,0,0,0,6,0V8h5a1,1,0,0,1,1,1v5H4ZM32.88,52H5a1,1,0,0,1-1-1V16H52V32.88A14.807,14.807,0,0,0,47,32,15.018,15.018,0,0,0,32,47,14.807,14.807,0,0,0,32.88,52ZM47,60A13,13,0,1,1,60,47,13.012,13.012,0,0,1,47,60Z"></path>
        <path d="M56.021,40.979a1,1,0,0,0-1.415,0L44,51.586l-4.95-4.95a1,1,0,0,0-1.414,1.414l5.657,5.657a1,1,0,0,0,1.414,0L56.021,42.394A1,1,0,0,0,56.021,40.979Z"></path>
      </g>
    </svg>
  );
}
