import React from "react";
import "./style.css";

export default function LongTermFreedom() {
  return (
    <section className="your-journey longtermfreedom pt pb">
      <div className="container">
        <div className="text-center fasater-title">
          <h2>A New Solution for Long Term Freedom From Arthritis Pain</h2>
          <p>
            We understand the anxiety that comes with surgical procedures, and
            that’s why we prioritize safety and comfort. Our minimally invasive
            techniques ensure that your procedure is virtually pain-free, with
            minimal scarring and quick recovery.
          </p>
        </div>

        <div className="yourjourney-main d-flex">
          <div className="yourjourney-box">
            <div className="yourjourney-pic">
              <img
                src="../images/arthrosamid/minimally-invasive.jpeg"
                className="img-fluid"
                alt="Minimally Invasive"
              />
            </div>
            <div className="yourjourney-info">
              <h5>
                Minimally Invasive
              </h5>
              <p>
              ARTHROSAMID ® is injected into the knee joint space as a minimally invasive, out-patient procedure. You do not need to stay in hospital for treatment. The treatment is performed with ultrasound guided precision.
              </p>
            </div>
          </div>

          <div className="yourjourney-box">
            <div className="yourjourney-pic">
            <img
                src="../images/platelet-rich-fibrin/step-process6.jpeg"
                className="img-fluid"
                alt="Safe"
              />
            </div>
            <div className="yourjourney-info">
              <h5>
              Safe
              </h5>
              <p>
                You will have a consultation with our doctor who will listen to
                your concerns and examine the skin lesion. If safe to do so, and
                you wish to proceed then the lesion can be removed at the same
                appointment. 
              </p>
            </div>
          </div>

          <div className="yourjourney-box">
            <div className="yourjourney-pic">
            <img
                src="../images/arthrosamid/long-lastingt.jpeg"
                className="img-fluid"
                alt="Long lastingt"
              />
            </div>
            <div className="yourjourney-info">
              <h5>
              Long lastingt
              </h5>
              <p>
                We routinely offer a follow up appointment within 1-2 weeks for
                a wound check and stitch removal (where needed). This gives you
                reassurance we are by your side to answer any questions or
                concerns and manage any complications should they arise. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
