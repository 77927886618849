import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Arthrosamid from '../../pages/Arthrosamid';
import Durolane from '../../pages/Durolane';
import Faq from '../../pages/Faq';
import Home from '../../pages/Home';
import MinorSurgery from '../../pages/MinorSurgery';
import OstenilPlus from '../../pages/OstenilPlus';
import PlateletRichFibrin from '../../pages/PlateletRichFibrin';
import Pricing from '../../pages/Pricing';
import SteroidInjection from '../../pages/SteroidInjection';
import ContactUs from '../../pages/ContactUs';
import GTM from '../GoogletagManager/GTM';


export default function NavigationRouting() {
  return (
    <><GTM/>
    <Routes>      
    <Route path="/" element={<Home />} />
    <Route path="/minor-surgery" element={<MinorSurgery />} />
    <Route path="/steroid-injection" element={<SteroidInjection />} />    
    <Route path="/platelet-rich-fibrin" element={<PlateletRichFibrin />} />        
    <Route path="/arthrosamid" element={<Arthrosamid/>} />    
    <Route path="/ostenil-plus" element={<OstenilPlus/>} />       
    <Route path="/durolane" element={<Durolane/>} />    
    <Route path="/faq" element={<Faq/>} />    
    <Route path="/pricing" element={<Pricing/>} />   
    <Route path="/contact-us" element={<ContactUs/>} />       
    
    </Routes>
    </>
  );
}
