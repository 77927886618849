import React from "react";
import data from "./data";
import "./style.css";

export default function RestoreLubrication() {
  return (
    <section className="restoreLu-brication pb pt">
      <div className="container">
        <div className="text-center">
          <h2>Key Benefits</h2>
        </div>
        <div className="restoreLu-brication-main d-flex">
              {data.slice(0, 3).map((item, index) => {
              return (
                <div className="restoreLu-brication-box" key={item.id}>
                  <div className="restoreLu-brication-no">{String(index + 1).padStart(2, '0')}</div>
                  <div className="restoreLu-brication-info">
                    {item.icon}
                    <span>{item.title}</span>
                    <p>{item.content}</p>
                  </div>
                </div>
              );
            })}
        </div>      
    
      </div>
    </section>
  );
}
