import React from "react";

export default function Mltipurpose() {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_931_16" fill="white">
        <path d="M0 0H56.6691V55.9029H0V0Z" />
      </mask>
      <path
        d="M56.6691 52.9029H0V58.9029H56.6691V52.9029Z"
        fill="#162731"
        mask="url(#path-1-inside-1_931_16)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000261506 8.50475C0.0238246 10.2956 0.000261506 46.0644 0.000261506 46.0644H1.72039L1.72039 21.2053C1.72039 20.1921 5.60828 20.2863 5.96173 16.3748C6.29161 12.4633 -0.0468647 6.17201 0.000261506 8.50475Z"
        fill="url(#paint0_linear_931_16)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.0431 8.5548L55.4436 8.57837L56.6689 23.3525V33.2725L55.7264 45.5253H55.3729H54.8545H54.5011L53.5586 33.2725V22.2921L54.6661 8.53125L55.0431 8.5548Z"
        fill="url(#paint1_linear_931_16)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5434 38.9492H36.355L32.3492 32.4223C32.3492 32.4223 32.3492 22.5964 32.3492 21.6775C32.3492 20.8056 19.1068 10.3672 18.5177 10.3672C17.9286 10.3672 29.0739 20.2637 28.9561 21.6775C28.8383 23.0206 28.9561 32.4223 28.9561 32.4223L25.6808 38.9728C23.4423 39.232 21.6987 41.117 21.6987 43.4262C21.6987 45.9004 23.7016 47.9032 26.1757 47.9032C28.6498 47.9032 30.6527 45.9004 30.6527 43.4262C30.6527 41.4469 29.3803 39.7739 27.5895 39.1849C28.0607 38.4073 30.6998 33.8125 30.6998 33.624L34.3521 39.5147C33.009 40.2923 32.0665 41.7532 32.0665 43.4027C32.0665 45.8768 34.0693 47.8797 36.5434 47.8797C39.0176 47.8797 41.0204 45.8768 41.0204 43.4027C41.0204 40.9285 39.0176 38.9257 36.5434 38.9257V38.9492ZM28.8618 43.4027C28.8618 44.8871 27.6602 46.0889 26.1757 46.0889C24.6912 46.0889 23.4895 44.8871 23.4895 43.4027C23.4895 41.9182 24.6912 40.7165 26.1757 40.7165C27.6602 40.7165 28.8618 41.9182 28.8618 43.4027ZM30.6998 32.4223C30.2992 32.4223 29.9693 32.0924 29.9693 31.6918C29.9693 31.2912 30.2992 30.9613 30.6998 30.9613C31.1003 30.9613 31.4302 31.2912 31.4302 31.6918C31.4302 32.0924 31.1003 32.4223 30.6998 32.4223ZM36.5434 46.0653C35.0825 46.0653 33.8808 44.8636 33.8808 43.4027C33.8808 41.9418 35.0825 40.74 36.5434 40.74C38.0044 40.74 39.2061 41.9182 39.2061 43.4027C39.2061 44.8871 38.0279 46.0653 36.5434 46.0653Z"
        fill="url(#paint2_linear_931_16)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_931_16"
          x1="0"
          y1="27.0322"
          x2="5.97417"
          y2="27.0322"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0170455" stopColor="#E8C353" />
          <stop offset="1" stopColor="#162731" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_931_16"
          x1="53.5586"
          y1="27.0283"
          x2="56.6689"
          y2="27.0283"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0170455" stopColor="#E8C353" />
          <stop offset="1" stopColor="#162731" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_931_16"
          x1="18.4951"
          y1="29.1352"
          x2="41.0204"
          y2="29.1352"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0170455" stopColor="#E8C353" />
          <stop offset="1" stopColor="#162731" />
        </linearGradient>
      </defs>
    </svg>
  );
}
