import React from "react";
import "./style.css";

export default function UnparralleledAccuracy() {
  return (
    <section className="unparralleled-accuracy pt">
      <div className="text-center">
       <h2>Unparralleled Accuracy</h2>   
       </div>

        <div className="unparralleled-main d-flex flex-wrap">          
            <div className="unparralleled-pic">
            <video controls autoPlay loop muted>
            <source src="images/Home/unparralleled.mp4" type="video/mp4" />
            Your browser does not support the video tag.
            </video>

            </div>
            <div className="unparralleled-info">                                  
              <p>If you have ever had a joint injection that has not worked, you may find yourself wondering whether the medicine was put in the right place? Current evidence shows image guided injections to be significantly more accurate than blind injections (1).</p>
              <p>This video shows the precision accuracy required to deliver the medicine directly to the knee joint space in our patient.</p>              
            </div>
          </div>      
    </section>
  );
}
