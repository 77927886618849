export const PricingData = [
    { treatment: 'Steroid Injection', note: 'Per Joint', price: '£200' },
    { treatment: 'Platelet Rich Fibrin (PRF)', note: 'Per Joint', price: '£400' },
    { treatment: 'Ostenil Plus (Hyaluronic Acid)', note: 'Per Joint', price: '£280' },
    { treatment: 'Arthrosamid', note: 'Per Joint', price: '£2000' },
    { treatment: 'Durolane (Hyaluronic Acid)', note: 'Per Joint', price: '£350' },        
];

export const PricingCombinationData = [
    { treatment: 'Ostenil Plus and Steroid Combination', note: 'Per Joint', price: '£400' },
    { treatment: 'Ostenil Plus and PRF Combination', note: 'Per Joint', price: '£550' },
    { treatment: 'Hip Joint Steroid Injection', note: 'This is for hip joint arthritis (not trochanteric bursitis)', price: '£400' },
    { treatment: 'Hip Joint Ostenil Plus Injection', note: 'This is for hip joint arthritis (not trochanteric bursitis)', price: '£500' },
    { treatment: 'Hip Joint PRF Injection', note: 'This is for hip joint arthritis (not trochanteric bursitis)', price: '£650' },        
];

export const MinorSurgeryData = [
        { treatment: 'Minor Surgery (Moles/Cysts/Lipomas)', note: '', price: '£450' },
    { treatment: 'Skin Tag Removal', note: '', price: '£150' }, 
    // { treatment: 'Steroid injection', note: 'Per Joint', price: '£200' },
    // { treatment: 'Platelet Rich Fibrin (PRF)', note: 'Per Joint', price: '£400' },    
    // { treatment: 'Ostenil Plus (Hyaluronic acid)', note: 'Per Joint', price: '£280' },    
];



