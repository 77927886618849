import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowRignt from "../Icons/ArrowRignt.js";
import Navigation from "../Navigation";
import "./style.css";
import useScrollToTop from "../../service/UseScrollToTop.js";

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollToTop = useScrollToTop();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setIsScrolled(true); // Add class when scrolled more than 1px
      } else {
        setIsScrolled(false); // Remove class when at the top
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={isScrolled ? "scroll" : ""}>
      <div className="header-top">
        <div className="container">
          <Link target="_blank"
            to="https://soneh-medical.uk2.cliniko.com/bookings?business_id=1553630126283957172&practitioner_id=1551701359156273513"
            className="d-flex justify-content-center align-items-center"
          >
            <img width="20" height="20" src="../images/cricket.png" alt="" />{" "}
            <span>Book Your Consultation</span> <ArrowRignt />
          </Link>
        </div>
      </div>
      <div className="header-second">
        <div className="container">
          <div className="d-flex justify-content-between col-12">
            <Link to="/" className="header-logo" onClick={scrollToTop}>
              <img
                src="../images/logo.png"
                alt="Skin & Joint Injection Clinic"
              />
            </Link>
            <Navigation />
          </div>
        </div>
      </div>
    </header>
  );
}
