import React from "react";
import "./style.css";

export default function ConditionsWeTreat() {
  return (
    <section className="conditions-wetreat pt">
         <div className="container-fluid">
          <div className="text-center fasater-title">
          <h2 className="fw-bold">Conditions we Treat</h2>  
          <p>List of the Conditions that we treat</p>        
        </div>

        <div className="conditions-main d-flex"> 
        <div className="conditions-box">                   
            <div className="title-three">          
            <h3><span>A</span>rthritis</h3>
            </div>
            <div className="conditions-info"> 
            <div className="osteoarthritis">
             <ul>
                <li>Rotator cuff </li>
                <li className="fasciitis">Trochanteric bursitis <small>(Greater trochanteric pain syndrome)</small></li>
                <li>Tennis and Golfer’s elbow</li>
                <li>Plantar Fasciitis</li>
                <li>Thumb (De Quervain’s)</li>
                <li>Achilles tendonitis</li>
             </ul>
            </div>
            </div>
            </div>

            <div className="conditions-box">                   
            <div className="title-three">          
            <h3><span>T</span>endinopathies</h3>
            </div>
            <div className="conditions-info"> 
            <div className="osteoarthritis">
            <ul>
                <li>Shoulder osteoarthritis</li>
                <li>Hip osteoarthritis</li>
                <li>Elbow osteoarthritis</li>
                <li>Knee osteoarthritis</li>
                <li>Wrist and base of thumb</li>
                <li>Ankle osteoarthritis</li>
                <li>Carpal Tunnel Syndrome</li>
                <li>Great toe</li>
             </ul>
            </div>
            </div>
            </div>
          </div>
          </div>      
    </section>
  );
}
