import React from "react";
import HeroSectionData from "../../component/HeroSection/HeroData";
import PageLayout from "../../component/PageLayout";
import Testimonial from "../../component/Testimonial";
import YouLoveForm from "../../component/YouLove";
import "./style.css";

export default function SteroidInjection() {
  return (
    <PageLayout>
      <HeroSectionData
        herotitleone="Skin & Joint Injection Clinic"
        herotitletwo="STEROID INJECTION"
        herocontent="Rapid pain relief for inflammation in arthritis and tendinopathy."
        herobtn="Book Your Consultation"
        imagespng="../images/steroid-injection/steroid-injection.jpeg"
      />      
      <section className="tendinopathy pt pb">
        <div className="container">
          <div className="ultrasound-guidance">
            <div className="ultrasound-inflammation">
              <div className="text-center">
                <span>
                  Rapid pain relief for inflammation in arthritis and
                  tendinopathy.
                </span>
                <h2>How Does It Work?</h2>
              </div>
              <p>
                Steroid injections, also known as cortisone injections, are a
                type of treatment commonly used to reduce inflammation and pain
                in a specific area of your body. They’re often used to treat a
                variety of conditions like arthritis, tendinitis, and bursitis,
                among others. 
                <strong>
                  We perform our steroid injection with Ultrasound Guidance to
                  ensure the treatment gets to the precise location where it is
                  needed.
                </strong>
              </p>
              <p>
                These injections typically contain a corticosteroid medication
                and a local anesthetic. The corticosteroid is a type of steroid
                that’s very similar to a natural hormone produced in your body
                called cortisol. It’s not the same type of steroid that some
                athletes take illegally, which is a different class of drugs
                called anabolic steroids. Steroid injections work by reducing
                inflammation in the targeted area. Inflammation is your body’s
                natural response to injury or disease, but sometimes this
                inflammation can cause pain or damage to your tissues.
              </p>
              <p>
                The corticosteroid medication in the injection works by damping
                down your body’s immune response, which can help to reduce this
                inflammation and provide relief from pain. The local anesthetic
                in the injection helps to numb the area, providing immediate
                pain relief. The corticosteroid, on the other hand, usually
                starts to work within a few days following the injection, and
                its effects can last several weeks or even longer.
              </p>
              <p>
                They are usually a part of a broader treatment plan that may
                include physical therapy, lifestyle modifications, and other
                medications. 
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="keybenefits pt pb">
        <div className="container">
          <div className="text-center">
            <h2>Key Benefits</h2>
          </div>

          <div className="d-flex keybenefits-main">
            <div className="keybenefits-info">
              <div className="inflammation-swelling">
                <div className="number">
                  <span>01</span>
                </div>
                <div className="swellinginfo">
                  <h5>Reduced Pain and Swelling</h5>
                  <p>
                    By reducing inflammation in the joint or area surrounding
                    the tendon, steroid injections can significantly decrease
                    pain levels. For those with osteoarthritis or tendinopathy,
                    this can mean the difference between discomfort during
                    everyday activities and a more normal, pain-free range of
                    motion.
                  </p>
                </div>
              </div>

              <div className="inflammation-swelling">
                <div className="number">
                  <span>02</span>
                </div>

                <div className="swellinginfo">
                  <h5>Improved Mobility</h5>
                  <p>
                    As a result of decreased inflammation and pain, individuals
                    often experience improved mobility. This benefit can have a
                    significant impact on the quality of life, especially for
                    those with osteoarthritis, who may find it difficult to
                    perform even simple tasks due to joint pain.
                  </p>
                </div>
              </div>

              <div className="inflammation-swelling">
                <div className="number">
                  <span>03</span>
                </div>

                <div className="swellinginfo">
                  <h5>Rapid Relief</h5>
                  <p>
                    Steroid injections often provide relatively rapid relief. In
                    some cases, patients may experience a decrease in symptoms
                    within a few days of their injection. This makes steroid
                    injections an attractive option for those in need of quick
                    symptom reduction.
                  </p>
                </div>
              </div>
            </div>

            <div className="keybenefits-pic">
              <img
                src="images/steroid-injection/key-benefits.png"
                alt="Key Benefits"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="treatedconditions pt">
        <div className="container">
          <div className="ultrasound-guidance">
            <div className="ultrasound-inflammation">
              <div className="text-center">
                <span>
                Rapid pain relief for inflammation in arthritis and tendinopathy.
                </span>
                <h2>What Conditions Can Be Treated?</h2>
              </div>
              <p>
              Using the precision of Ultrasound guidance we are able to deliver the medication directly to the source of the problem. The following conditions can be treated. We do not perform spinal injection for the neck, lower back or sacroiliac joints.
              </p>
              <ul>
                <li>Osteoarthritis</li>
                <li>Frozen shoulder</li>
                <li>Shoulder tendinopathy</li>
                <li>Carpal Tunnel syndrome</li>
                <li>Plantar fasciitis</li>
                <li>Bursitis</li>
                <li>Trigger Finger</li>
                <li>Morton’s Neuroma</li>
                </ul>              
            </div>
          </div>
        </div>
      </section>

      <div className="mt">
      <Testimonial />
      </div>
      <YouLoveForm />
    </PageLayout>
  );
}
