import React from 'react'
import PageLayout from '../../component/PageLayout'
import HeroSectionData from '../../component/HeroSection/HeroData'
import YouLoveForm from '../../component/YouLove'

export default function ContactUs() {
  return (
    <PageLayout>
      <HeroSectionData herobtn="Book Your Consultation" imagespng="../images/contact-us.jpg"/>
      <YouLoveForm/>
    </PageLayout>
  )
}
