import React from "react";

export default function SteroidInjectionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80px"
      height="80px"
      viewBox="0 0 118 117"
      fill="none"
    >
      <path
        d="M99.9311 34.1239C99.1355 34.1239 98.4891 34.0668 97.9846 33.9805C97.9217 31.5997 98.4277 27.9817 99.5655 24.8302C100.874 25.3918 102.879 26.6012 105.229 28.9615C106.851 30.5994 107.48 31.8849 107.677 32.524C107.419 32.6761 106.996 32.8852 106.376 33.0753C104.136 33.7612 101.913 34.1239 99.9311 34.1239Z"
        fill="#F4F5F5"
      />
      <path
        d="M112.152 22.0985C104.001 13.8812 94.8814 12.1673 91.7811 18.2904C88.6866 24.393 87.3675 33.495 88.8621 38.5886C90.3582 43.6822 99.4748 45.3961 109.229 42.3967C118.989 39.4075 120.292 30.3245 112.152 22.0985ZM106.376 33.0753C104.136 33.7612 101.911 34.1239 99.9311 34.1239C99.1356 34.1239 98.4892 34.0668 97.9846 33.9805C97.9218 31.5997 98.4277 27.9817 99.5655 24.8303C100.874 25.3918 102.879 26.6012 105.229 28.9616C106.851 30.5995 107.48 31.8849 107.677 32.524C107.419 32.6761 106.996 32.8852 106.376 33.0753Z"
        fill="#BDBCBC"
      />
      <path
        d="M103.486 27.3587C101.892 26.0236 100.537 25.2485 99.5655 24.8302C98.4277 27.9817 97.9217 31.5997 97.9846 33.9805C98.4891 34.0668 99.1355 34.1239 99.9311 34.1239C100.8 34.1239 101.721 34.042 102.663 33.9074C102.628 32.1511 102.892 29.7512 103.486 27.3587Z"
        fill="#E0E0E0"
      />
      <path
        d="M78.637 116.996L74.2951 90.9443C73.2539 84.7086 68.3373 78.9482 62.3326 76.9491L59.0056 75.845L77.5811 70.8581C83.5756 69.2466 88.4922 65.3829 91.8323 62.0501C93.9718 66.6406 93.4951 72.9245 90.4094 76.7604L88.2523 79.4366V116.999H78.637V116.996Z"
        fill="#F4F5F5"
      />
      <path
        d="M84.567 116.996H78.6355L74.2936 90.9443C73.2524 84.7086 68.3358 78.9482 62.3311 76.9491L59.0041 75.845L77.5796 70.8581C83.5741 69.2466 88.4907 65.3829 91.8308 62.0501C92.7814 64.0887 93.185 66.4592 93.1046 68.802C88.6705 72.5662 83.8885 75.2205 79.0976 76.5074L74.0289 77.8689C77.1321 81.2456 79.3097 85.4764 80.0628 89.9821L84.567 116.996Z"
        fill="#E0E0E0"
      />
      <path
        d="M12.8476 0L71.1933 33.6749C72.3164 34.3227 73.1193 35.3801 73.4586 36.6363C74.1547 39.2262 72.606 41.9097 70.0073 42.6058L50.9697 47.6979C43.9004 28.1631 26.3193 13.014 0.503387 4.02747V0H12.8476Z"
        fill="#F4F5F5"
      />
      <path
        d="M73.4893 36.8308L53.7643 42.1086C45.4988 23.4132 28.0786 8.92947 3.10501 0H0.503387V4.02747C26.3193 13.0154 43.9004 28.1631 50.9697 47.6994L70.0073 42.6073C72.5416 41.9287 74.0611 39.3578 73.4893 36.8308Z"
        fill="#E0E0E0"
      />
      <path
        d="M53.8784 84.4131L59.2542 86.2031C61.7944 87.0513 64.2366 89.9074 64.6812 92.5558L68.7496 116.997H78.6369L74.2951 90.9457C73.2538 84.71 68.3372 78.9496 62.3326 76.9505L59.0056 75.8464L77.5811 70.8595C83.5755 69.248 88.4921 65.3843 91.8323 62.0515C93.9718 66.642 93.495 72.9259 90.4093 76.7618L88.2523 79.438V117H98.0007V82.8703C104.5 74.7978 104.5 61.6961 98.0007 53.6236C98.0007 53.6236 96.493 52.11 94.6459 50.2615C92.7975 48.413 90.051 48.7187 88.5374 50.9269C88.5374 50.9269 82.7536 59.3738 75.0555 61.4387L53.9573 67.0851C51.3674 67.7797 49.2542 70.5393 49.2542 73.2243C49.2542 73.2243 49.2542 75.3945 49.2542 78.0429C49.2557 80.7001 51.3309 83.5664 53.8784 84.4131Z"
        fill="#BDBCBC"
      />
      <path
        d="M82.875 34.1136C81.8265 30.2104 79.3053 27.1057 76.0573 25.2309L32.3459 0H12.8476L71.1933 33.6749C72.3164 34.3227 73.1193 35.3801 73.4586 36.6363C74.1547 39.2262 72.606 41.9097 70.0073 42.6058L50.9697 47.6979C43.9004 28.1631 26.3193 13.014 0.503387 4.02747V14.4164C22.6355 22.8794 38.1399 36.6948 43.0098 54.8813C43.7044 57.4888 46.3806 59.0228 48.9793 58.3252L72.5343 52.0237C80.3334 49.9281 84.9648 41.9112 82.875 34.1136Z"
        fill="#BDBCBC"
      />
      <path
        d="M67.7771 111.146L68.7496 116.996H78.637L77.6645 111.146H67.7771Z"
        fill="#9D9D9D"
      />
      <path
        d="M98.0022 111.146H88.2538V116.996H98.0022V111.146Z"
        fill="#9D9D9D"
      />
    </svg>
  );
}
