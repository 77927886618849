import React from "react";
import "./style.css";

export default function YourJourney() {
  return (
    <section className="osteoarthritis-two">
      <div className="osteoarthritis-remained pt">
        <div className="container">
          <div className="text-center gradiant-title">
            <h2>Anne’s Journey</h2>
          </div>
          <div className="facilities-info">
          <p>
            Anne, like many of us, lived an active life. But then knee pain from
            osteoarthritis began to creep in. The pain was there when she woke
            up, persisted throughout the day, and even disturbed her much-needed
            sleep. She sought relief, and had even tried an unguided steroid
            injection previously, but the results were disappointing – the
            unrelenting pain remained.
          </p>
          <p>
            Anne, like many of us, lived an active life. But then knee pain from
            osteoarthritis began to creep in. The pain was there when she woke
            up, persisted throughout the day, and even disturbed her much-needed
            sleep. She sought relief, and had even tried an unguided steroid
            injection previously, but the results were disappointing – the
            unrelenting pain remained.
          </p>
          <p>
            Anne’s story is all too relatable for many people. If you are
            wrestling with the persistent pain of osteoarthritis, know that
            there’s a path to relief. We’re here to walk it with you, just as we
            did with Anne.
          </p>
        </div>
        </div>
      </div>

      <div className="your-comfort">
        <div className="container">
          <div className="comfort-logo">
            <img
              src="../images/Home/logo-big.svg"
              alt="We Care About Your Comfort And Safety"
            />
          </div>

          <div className="comfort-info">
            <div className="text-center comfort-title">
              <h2>We Care About Your Comfort And Safety</h2>
            </div>
            <div className="facilities-info">
            <p>
              We’re proud to offer state-of-the-art facilities to ensure the
              highest quality care for our patients. Our clinic has been
              designed with the utmost patient comfort in mind.
            </p>
            <p>
              A key feature of our clinic is our purpose-built procedure
              theatre, tailored specifically for ultrasound-guided injections.
              Here we carry out effective, minimally invasive treatments in a
              safe and controlled environment.
            </p>
            <p>
              Our facilities are not just clean and clinical, they are designed
              to offer a calm, relaxing atmosphere.
            </p>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}
