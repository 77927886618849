import React from "react";
import AppointmentBooking from "../../component/AppointmentBooking";
import ChooseUltrasound from "../../component/ChooseUltrasound";
import ClinicDirector from "../../component/ClinicDirector";
import ConditionsWeTreat from "../../component/ConditionsWeTreat";
import HeroSectionData from "../../component/HeroSection/HeroData";
import JointPain from "../../component/JointPain";
import NonSurgical from "../../component/NonSurgical";
import PageLayout from "../../component/PageLayout";
import Testimonial from "../../component/Testimonial";
import UnparralleledAccuracy from "../../component/UnparralleledAccuracy";
import YouLoveForm from "../../component/YouLove";
import YourJourney from "../../component/YourJourney";
export default function Home() {
  return( 
  <PageLayout>
      <HeroSectionData
    herotitleone="Skin & Joint Injection Clinic"
    herotitletwo="Experience Pain-Free Movement with Precision Ultrasound Guided Joint Injections"
    herocontent="Offering skin surgery for the removal of unwanted skin lesions."
    herobtn="Book Your Consultation"
    imagespng="../images/Home/HeroSlider.jpeg"   
    />    
    <JointPain/>
    <ChooseUltrasound/>
    <UnparralleledAccuracy/>
    <NonSurgical/>
    <ConditionsWeTreat/>
    <AppointmentBooking/>
    <YourJourney/>
    <ClinicDirector/>
    <Testimonial/>
    <YouLoveForm/>   
  </PageLayout>
  )
}
