import React, { useEffect, useRef } from 'react';

export default function MouseMovement({ contentRef, onBallHover }) {
  const ballRef = useRef(null);

  useEffect(() => {
    const ball = ballRef.current;
    let mouseX = 0;
    let mouseY = 0;
    let ballX = 0;
    let ballY = 0;
    const speed = 0.1;

    function animate() {
      if (!contentRef.current) return;

      const contentRect = contentRef.current.getBoundingClientRect();
      const contentHeight = contentRect.height;
      const innerWindowWidth = window.innerWidth;

      let distX = mouseX - ballX;
      let distY = mouseY - ballY;
      ballX = Math.max(0, Math.min(innerWindowWidth - 160, ballX + distX * speed));
      ballY = Math.max(0, Math.min(contentHeight - 160, ballY + distY * speed));
      ball.style.left = ballX + 'px';
      ball.style.top = ballY + 'px';

      // Detect proximity to icons
      onBallHover(ballX, ballY);
    }

    function handleMouseMove(event) {
      mouseX = event.pageX;
      mouseY = event.pageY;
      animate();
    }

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [contentRef, onBallHover]);

  return <div ref={ballRef} className="ball"></div>;
}
