import IconMovementOne from "../Icons/IconMovementOne";
import IconMovementThree from "../Icons/IconMovementThree";
import IconMovementTwo from "../Icons/IconMovementTwo";

const data = [
     {
        id:"1",
        icon: <IconMovementOne/>, 
        title:"Restore Lubrication and Movement",
        content:"OSTENIL PLUS enhances the joint’s natural lubrication, restoring its lubricating, shock-absorbing and filtering properties. This in turn leads to reduced stiffness and improved movement, allowing patients to participate in daily activities with greater ease." 
      },
      {
          id:"2",
          icon: <IconMovementTwo/>, 
          title:"Reduced Inflammation",
          content:"OSTENIL PLUS reduces inflammation of the synovial membrane. This can lead to significant reduction in pain and swelling. Many patients experience a noticeable decrease in pain within a few weeks of the injection." 
        },
        {
          id:"3",
          icon: <IconMovementThree/>, 
          title:"Safe and Sustained",
          content:"OSTENIL PLUS injections are generally well-tolerated, with most people experiencing few side effects. For patients looking to avoid or delay surgery, Ostenil Plus injections can serve as a non-invasive alternative to help manage symptoms foir 6 months or more." 
        },
        {
          id:"4",
          icon: <IconMovementOne/>, 
          title:"Reduce Pain and Stiffness",
          content:"As early as 2 weeks after an injection, DUROLANE can reduce OA joint pain and improve the physical activity and quality of life of OA patients. Some patients have pain relief lasting up to 12 months" 
        },
        {
            id:"5",
            icon: <IconMovementTwo/>, 
            title:"Long Lasting",
            content:"What makes DUROLANE different is its concentration of hyaluronic acid, which has been carefully stabilized to resist degradation, extending the time DUROLANE stays in the joint and adding to its long-lasting effect." 
          },
          {
            id:"6",
            icon: <IconMovementThree/>, 
            title:"Safe",
            content:"Introduced worldwide in 2001, DUROLANE has been tested in more clinical studies than any other single-injection hyaluronic therapy on the market. It is proven safe for repeated courses of therapy and provides longer-lasting pain relief versus steroid injections.11 The hyaluronic acid in DUROLANE is also derived from a nonanimal source to reduce any risk of allergic reaction." 
          }
    ];


export default data;