import React from 'react'
import './style.css'

export default function UltraSoundguide() {
  return (
    <section className="ultrasoundguide pt80 pb80">
        <div className="container">
              <div className="text-center">           
                <h3>"We Use Precision Ultrasound To Guide Our Injections to Exactly Where They Are Needed - No Guessing"</h3>
              </div>                          
          </div>        
      </section>
  )
}
