import React from 'react'
import { Link } from 'react-router-dom'

export default function ResourcesInformation({title, content, LinkOne, LinkTwo, pdfOne, pdfTwo}) {
  return (
    <section className="doseprf pt80 pb80 text-center">
    <div className="container">
           <h2>{title}</h2>              
         <p>{content}</p>
         <div className="buttons d-flex justify-content-center mt-4 gap-3">
            <Link to={pdfOne} target="_blank" className="btn">{LinkOne}</Link>
            <Link to={pdfTwo} target="_blank" className="btn">{LinkTwo}</Link>
         </div>
      </div>        
  </section>
  )
}
