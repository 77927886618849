import React from "react";

export default function IconPlus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M6.33301 12H12.333M18.333 12H12.333M12.333 12V6M12.333 12V18"
        stroke="#4C4C4C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
