import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ToolKit from '../../component/Icons/SkinIcons/ToolKit';

// Forward the ref to useImperativeHandle
const BgIcons = forwardRef((props, ref) => {
  const iconRefs = useRef([]); // To hold references to each icon
  const [iconColors, setIconColors] = useState(Array(20).fill('black'));

  const handleMouseEnter = (index, color) => {
    setIconColors((prevColors) => {
      const newColors = [...prevColors];
      newColors[index] = color;
      return newColors;
    });
  };

  const handleMouseLeave = (index) => {
    setIconColors((prevColors) => {
      const newColors = [...prevColors];
      newColors[index] = 'black';
      return newColors;
    });
  };

  const handleBallHover = (ballX, ballY) => {
    iconRefs.current.forEach((icon, index) => {
      if (icon) {
        const rect = icon.getBoundingClientRect();
        const isHovering =
          ballX >= rect.left && ballX <= rect.right && ballY >= rect.top && ballY <= rect.bottom;
        if (isHovering) {
          handleMouseEnter(index, 'red');
          console.log("handleMouseEnter", handleMouseEnter);
        } else {
          handleMouseLeave(index);
        }
      }
    });
  };

  // Expose the handleBallHover method via ref
  useImperativeHandle(ref, () => ({
    handleBallHover
  }));

  return (
    <div className='doctorissues'>
      {Array.from({ length: 20 }, (_, index) => (
        <div
          key={index}
          className={`lottieanimation lottie-${index + 1}`}
          ref={(el) => (iconRefs.current[index] = el)} // Store each icon's ref
        >
          <ToolKit color={iconColors[index]} />
        </div>
      ))}
    </div>
  );
});

export default BgIcons;
