import React from "react";

export default function ToolKit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 58 40"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.500262 0.504754C0.523825 2.29555 0.500262 38.0644 0.500262 38.0644H2.22039L2.22039 13.2053C2.22039 12.1921 6.10828 12.2863 6.46173 8.37482C6.79161 4.46335 0.453135 -1.82799 0.500262 0.504754Z"
        fill="url(#paint0_linear_190_51)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.5432 0.555288L55.9437 0.578857L57.1691 15.3529V25.273L56.2265 37.5258H55.873H55.3547H55.0012L54.0587 25.273V14.2926L55.1662 0.531738L55.5432 0.555288Z"
        fill="url(#paint1_linear_190_51)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0436 30.949H36.8551L32.8493 24.422C32.8493 24.422 32.8493 14.5962 32.8493 13.6772C32.8493 12.8054 19.6069 2.36694 19.0178 2.36694C18.4287 2.36694 29.5741 12.2634 29.4562 13.6772C29.3384 15.0203 29.4562 24.422 29.4562 24.422L26.1809 30.9726C23.9424 31.2318 22.1988 33.1168 22.1988 35.426C22.1988 37.9001 24.2017 39.903 26.6758 39.903C29.1499 39.903 31.1528 37.9001 31.1528 35.426C31.1528 33.4467 29.8804 31.7737 28.0896 31.1846C28.5609 30.407 31.1999 25.8122 31.1999 25.6237L34.8522 31.5145C33.5091 32.2921 32.5666 33.753 32.5666 35.4024C32.5666 37.8765 34.5694 39.8794 37.0436 39.8794C39.5177 39.8794 41.5205 37.8765 41.5205 35.4024C41.5205 32.9283 39.5177 30.9254 37.0436 30.9254V30.949ZM29.362 35.4024C29.362 36.8869 28.1603 38.0886 26.6758 38.0886C25.1913 38.0886 23.9896 36.8869 23.9896 35.4024C23.9896 33.9179 25.1913 32.7162 26.6758 32.7162C28.1603 32.7162 29.362 33.9179 29.362 35.4024ZM31.1999 24.422C30.7993 24.422 30.4694 24.0921 30.4694 23.6915C30.4694 23.291 30.7993 22.9611 31.1999 22.9611C31.6005 22.9611 31.9303 23.291 31.9303 23.6915C31.9303 24.0921 31.6005 24.422 31.1999 24.422ZM37.0436 38.0651C35.5827 38.0651 34.3809 36.8633 34.3809 35.4024C34.3809 33.9415 35.5827 32.7398 37.0436 32.7398C38.5045 32.7398 39.7062 33.9179 39.7062 35.4024C39.7062 36.8869 38.528 38.0651 37.0436 38.0651Z"
        fill="url(#paint2_linear_190_51)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_190_51"
          x1="0.5"
          y1="19.0322"
          x2="6.47417"
          y2="19.0322"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0170455" stopColor="#" />
          <stop offset="1" stopColor="#" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_190_51"
          x1="54.0587"
          y1="19.0288"
          x2="57.1691"
          y2="19.0288"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0170455" stopColor="#" />
          <stop offset="1" stopColor="#" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_190_51"
          x1="18.9952"
          y1="21.135"
          x2="41.5205"
          y2="21.135"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0170455" stopColor="#" />
          <stop offset="1" stopColor="#" />
        </linearGradient>
      </defs>
    </svg>
  );
}
