import React from 'react'
import Footer from '../Footer'
import Header from '../Header'

export default function PageLayout({children}) {
  return (
    <div className='PageLayout'>
     <Header/>
     {children}       
     <Footer/>
    </div>
  )
}
