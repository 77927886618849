import emailjs from "emailjs-com";
import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";

export default function YouLoveForm() {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });
  
  // New state for success message
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const templateParams = {
      to_name: formData.name,
      from_name: formData.name,
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
      message: formData.message,
    };
  
    emailjs.send("service_5ruravo", "template_1m7g4mg", templateParams, "Fe71ZyCCxhjk6hvcX")
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        
        setSuccessMessage("Email sent successfully!");
  
        setFormData({
          name: "",
          mobile: "",
          email: "",
          message: "",
        });
  
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      }, (err) => {
        console.error("Failed to send email. Error: ", err);
      });
  };
  

  return (
    <>
      <section className="youlove pt pb">
        <div className="container">
          <div className="text-center youlove-title position-relative">
            <h2>Get Back To Doing What you Love</h2>
          </div>
          <div className="youlove-main position-relative">
            <h3>Contact Us Today</h3>
            
            {/* Display success message here */}
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="form-input form-input02">
                <input 
                  type="text" 
                  name="name" 
                  className="form-control boder-right" 
                  placeholder="Enter your Name" 
                  value={formData.name} 
                  onChange={handleChange} 
                  required
                />
                <input 
                  type="text" 
                  name="mobile" 
                  className="form-control boder-left" 
                  placeholder="Enter Mobile Number" 
                  value={formData.mobile} 
                  onChange={handleChange} 
                  required
                />
              </div>
              <div className="form-input">
                <input 
                  type="email" 
                  name="email" 
                  className="form-control" 
                  placeholder="Enter your Email" 
                  value={formData.email} 
                  onChange={handleChange} 
                  required
                />
              </div>
              <div className="form-input">
                <textarea 
                  name="message" 
                  className="form-control" 
                  placeholder="Enter your Message" 
                  value={formData.message} 
                  onChange={handleChange} 
                  required
                ></textarea>
              </div>
              <button className="btn" type="submit">Send</button>
            </form>
          </div>
        </div>
      </section>
      <section className="painfree overflow-hidden position-relative">
        <div className="container">
          <h4>You are one call away from a pain-free life</h4>
          <div className="buttoncenter">
          <Link className="btn" to="https://soneh-medical.uk2.cliniko.com/bookings?business_id=1553630126283957172&practitioner_id=1551701359156273513" target="_blank">Book Your Consultation</Link>
          </div>
        </div>      
      </section>
    </>
  );
}
