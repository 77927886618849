import React from "react";
import "./style.css";

export default function ChooseUltrasound() {
  return (
    <section className="ultrasound-injections pt">
      <div className="container">
        <div className="text-center">
          <h2>Why Choose Ultrasound Guided Injections?</h2>
        </div>

        <div className="ultrasound-main d-flex">
          <div className="ultrasound-box">
            <div className="ultrasound-no">01</div>
            <div className="ultrasound-info">
            <span>Targeted Results</span>
              <p>
                Our ultrasound-guided injections are the equivalent of turning
                on the lights in a dark room. We deliver the medication directly
                to the source of your pain, leading to rapid relief and reducing
                the need for repeated treatments. Ultrasound-guided injections
                have been shown to be significantly more effective than
                un-guided injections.
              </p>
            </div>
          </div>
          <div className="ultrasound-box">
            <div className="ultrasound-no">02</div>
            <div className="ultrasound-info">
            <span>Safe and Sound Approach</span>
              <p>
                By using the ultrasound guidance, we can avoid any collateral
                damage to the surrounding tissues, nerves, or blood vessels,
                significantly reducing any risk of complications. Your health
                deserves no guesswork, and we ensure it doesn't get any.
              </p>
            </div>
          </div>
          <div className="ultrasound-box">
            <div className="ultrasound-no">03</div>
            <div className="ultrasound-info">
            <span>Improved Comfort and Satisfaction</span>
              <p>
                Let's cut down the discomfort associated with injections. Our
                technology allows us to find the most efficient route to the
                target, often limiting the number of injections you need. Say
                goodbye to the apprehension of pain that usually comes with
                traditional injections.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
