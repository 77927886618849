import React from "react";
import Mltipurpose from "../Icons/Mltipurpose";
import "./style.css";

export default function JointPain() {
  return (
    <section className="jointpain-main position-relative pt">      
      <div className="container">
        <div className="jointpain text-center">
          <h2>Joint Pain Can Be Debilitating</h2>
          <p>
            If you are one of the millions of people living with arthritis,
            aren’t you tired of living with relentless joint pain or dealing
            with the persistent inability to do the things you once loved? Does
            it feel like these issues are holding you hostage, preventing you
            from fully enjoying your life?
          </p>
        </div>

        <div className="text-center">
          <h2>Don't Let Joint Pain Rule Your Life</h2>
        </div>

        <div className="pain-main d-flex">
          <div className="pain-box">
            <div className="pain-pic">
              <img
                src="../images/Home/traditional-injection.png"
                alt="Traditional injection treatments might have let you down before"
                className="img-fluid"
              />
            </div>
            <div className="pain-info">
              <Mltipurpose />
              <p>
                Traditional injection treatments might have let you down before.
                Maybe you’ve experienced the frustration of repeated injections
                that don’t quite hit the mark or even caused additional
                discomfort. The process itself can feel like a shot in the dark,
                leaving you wondering if there’s a more effective, less painful
                way to get the relief you desperately need.
              </p>
            </div>
          </div>
          <div className="pain-box d-flex flex-column flex-md-column-reverse">
            <div className="pain-pic">
              <img
                src="../images/Home/joint-pain.png"
                alt="Joint and tendon pains can rob you"
                className="img-fluid"
              />
            </div>
            <div className="pain-info">
              <Mltipurpose />
              <p>
                Joint and tendon pains can rob you of the simple pleasures in
                life, like playing with your kids, enjoying a round of golf, or
                even just going for a morning walk. The longer these issues
                persist, the greater their impact on your life becomes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
