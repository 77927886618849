import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./style.css";
import data from "./data";

export default function Testimonial() {
  return (
    <section className="testimonial pt pb">
      <div className="container">
        <div className="text-center testimonial-title">
          <strong><span>Testimonial</span></strong>
          <h2>What our Clients say About us</h2>
        </div>

        <div className="testimonial-main">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000, disableOnInteraction: false }} 
            className="testimonial-swiper"
            breakpoints={{              
              640: {
                slidesPerView:1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1240: { 
                slidesPerView: 3,
                spaceBetween:20,
              }
            }}
          >
            {data.map((testimonial, id) => (
              <SwiperSlide key={id}>
                <div className="testimonial-box">
                  <div className="testimonial-info">
                    <h5>{testimonial.title}</h5>
                    <p>{testimonial.content}</p>
                  </div>
                  <div className="testimonial-pic">
                    <img
                      src={testimonial.pic}
                      className="img-fluid"
                      alt={testimonial.name}
                    />
                    <h6>{testimonial.name}</h6>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
