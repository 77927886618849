import React from "react";
import AppointmentsData from "../../component/AppointmentBooking/AppointmentsData";
import HeroSectionData from "../../component/HeroSection/HeroData";
import PageLayout from "../../component/PageLayout";
import ResourcesInformation from "../../component/ResourcesInformation";
import RestoreLubrication from "../../component/RestoreLubrication";
import Testimonial from "../../component/Testimonial";
import UltraSoundguide from "../../component/UltraSoundguide";
import YouLoveForm from "../../component/YouLove";
import './style.css';

export default function OstenilPlus() {
  return (
    <PageLayout>
      <HeroSectionData
        herotitleone="Skin & Joint Injection Clinic"
        herotitletwo="OSTENIL PLUS"
        herocontent="Revitalise Your Joints and experience the Freedom of Movement with Ostenil Plus for Osteoarthritis."
        herobtn="Book Your Consultation"
        imagespng="../images/ostenil-plus/ostenil-plus.jpeg"
      />      
      <section className="osteoarthritis-main pt pb">
        <div className="container">
        <div className="debilitating pb80">
          <p>
            Millions of people are living with the painful and debilitating
            symptoms of Osteoarthritis. The chronic stiffness, swelling, and
            pain in the joints can severely limit mobility and hinder the
            quality of life. Osteoarthritis is not just a minor inconvenience;
            it’s a progressive disease that can lead to a drastic reduction in
            one’s ability to perform daily tasks. It affects not only the
            physical well-being but also the mental health of the sufferers.
            Current therapies may provide temporary relief but often fail to
            address the underlying cause of the symptoms. Furthermore, oral
            medications can have systemic side effects, while surgical
            interventions are invasive and can require prolonged recovery. The
            problem  is complex and often requires a multimodal approach.
          </p>
          </div>
          <div className="text-center">
          <h2>What Happens to the Joint in Osteoarthritis?</h2>
          </div>
          <div className="debilitating-pic text-center">
            <img src="images/ostenil-plus/osteoarthritis.jpg" alt="What Happens to the Joint in Osteoarthritis?" className="img-fluid" />
          </div>
          <div className="significantly">
            <p>For too many of our patients, knee osteoarthritis is a fact of life, a daily struggle that significantly reduces their mobility, comfort, and enjoyment of life. Every step becomes an agonising experience, each movement of the joint a reminder of the deterioration happening within. The condition can be debilitating, impeding work, social, and family life. Traditional treatments – pain medication, physical therapy, even surgery – are often just temporary solutions are often short lived solutions with surgery being the final and most drastic step.</p>
            <p>Because of this change in the fluid and other more complicated reasons, the cushioning in the joint starts to wear away. In some spots, this cushioning, called cartilage, might even go away completely. When the fluid gets thin and the cushioning wears down, it leads to the common signs of osteoarthritis, like pain, stiffness, and swelling.</p>
          </div>
        </div>
      </section>

      <section className="introducing pt pb">
        <div className="container">
          <div className="discomfortsection-guidance">
            <div className="text-center">             
              <h2>
              Introducing Ostenil Plus
              </h2>
            </div>
            <div className="discomfort-main d-flex align-items-center justify-content-center">
              <div className="discomfort-pic">
                <img
                  src="images/ostenil-plus/ostenil-plus.jpg"
                  alt="Introducing Ostenil Plus"
                  className="img-fluid"
                />
              </div>
              <div className="discomfort-info">
                <p>
                A targeted and innovative treatment designed to alleviate the symptoms of osteoarthritis. OSTENIL PLUS contains hyaluronic acid, a natural substance found in the body that helps to lubricate and cushion the joints. We inject this into the joint using ultrasound guidance to ensure it gets to the exact place it needs to act, giving you added reassurance. It can provide 6 months or more relief from Osteoarthritis pain allowing you to do more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


    <RestoreLubrication/>
    <div className="gradinentthree">
    <UltraSoundguide/>
    </div>
    <div className="internationally-standard">
    <ResourcesInformation 
      title="What's the Evidence?"
      content={
        <>
          The Cochrane Review is an internationally recognized standard in evidence-based healthcare, investigating the<br /> effect of therapeutic interventions and treatments. 
          According to their review, hyaluronic acid (HA) is a viable<br /> treatment option for mild to moderate knee osteoarthritis, supported by strong evidence.
        </>
      }
      LinkOne="Read the Review"
      pdfOne="https://www.cochrane.org/CD005321/MUSKEL_viscosupplementation-for-the-treatment-of-osteoarthritis-of-the-knee"
      />
       </div>
       <AppointmentsData/>       
      <Testimonial />
      <YouLoveForm />
    </PageLayout>
  );
}
