import React from "react";
import HeroSectionData from "../../component/HeroSection/HeroData";
import PageLayout from "../../component/PageLayout";
import Testimonial from "../../component/Testimonial";
import YouLoveForm from "../../component/YouLove";
import { MinorSurgeryData, PricingCombinationData, PricingData } from "./PricingData";
import './style.css';

export default function Pricing() { 
  return (
    <PageLayout>
      <HeroSectionData        
        herobtn="Book Your Consultation"
        imagespng="../images/pricing/priceing.jpg"
      />    
      <section className="pricing-section pt pb">
      <div className="container">
      <div className="text-center">
        <h2>Pricing Plan</h2>
        </div>
      <div className="pricing-main">
        <div className="tissueinjections">
        <h3>Joint and Soft Tissue Injections with Ultrasound Guidance</h3>
        <div className="guidance-pic">
        <img src="images/pricing/ultrasound-guidance.jpg" alt="Joint and Soft Tissue Injections with Ultrasound Guidance" className="img-fluid"              />
        </div>
        <div className="guidance-boxmain d-flex">
        <div className="guidance-box d-flex justify-content-between flex-column">
        {PricingData.map(({ treatment, note, price }, index) => (
          <div key={index} className="guidance-info d-flex justify-content-between align-items-center">
            <p>{treatment} <span>{note}</span></p>
            <strong>{price}</strong>
          </div>
        ))}
      </div>

      <div className="guidance-box d-flex justify-content-between flex-column">
        {PricingCombinationData.map(({ treatment, note, price }, index) => (
          <div key={index} className="guidance-info d-flex justify-content-between align-items-center">
            <p>{treatment} <span>{note}</span></p>
            <strong>{price}</strong>
          </div>
        ))}
      </div>
  
        </div>
        </div>
       </div>
       <div className="pricing-main pricing-main02">
        <div className="tissueinjections">
        <h3>Minor Surgery</h3>
        <div className="guidance-pic">
        <img src="images/pricing/skin-surgery.jpg" alt="Minor Surgery" className="img-fluid"              />
        </div>
        <div className="guidance-boxmain d-flex justify-content-center">
        <div className="guidance-box d-flex justify-content-between flex-column">
        {MinorSurgeryData.map(({ treatment, note, price }, index) => (
          <div key={index} className="guidance-info d-flex justify-content-between align-items-center">
            <p>{treatment} <span>{note}</span></p>
            <strong>{price}</strong>
          </div>
        ))}
      </div>  
        </div>
        </div>
       </div>
      </div>
      </section>
      
      <Testimonial />
      <YouLoveForm />
    </PageLayout>
  );
}
