import React from "react";
import AppointmentBooking from "../../component/AppointmentBooking";
import HeroSectionData from "../../component/HeroSection/HeroData";
import LongTermFreedom from "../../component/LongTermFreedom";
import PageLayout from "../../component/PageLayout";
import ResourcesInformation from "../../component/ResourcesInformation";
import Testimonial from "../../component/Testimonial";
import UltraSoundguide from "../../component/UltraSoundguide";
import YouLoveForm from "../../component/YouLove";
import "./style.css";

export default function Arthrosamid() {
  return (
    <PageLayout>
      <HeroSectionData
        herotitleone="Skin & Joint Injection Clinic"
        herotitletwo="ARTHROSAMID"
        herocontent="3 years sustained relief from knee arthritis with a single treatment."
        herobtn="Book Your Consultation"
        imagespng="../images/arthrosamid/arthrosamid.jpg"
      />     
      
      <section className="osteoarthritisnew pt">
        <div className="container">
          <div className="discomfortsection-guidance">
            <div className="text-center">
              <span>Relief from knee arthritis</span>
              <h2>Fight Back Against Knee Osteoarthritis</h2>
            </div>
            <div className="discomfort-main d-flex align-items-md-start align-items-xl-center">
              <div className="discomfort-pic">
                <img
                  src="images/arthrosamid/osteoarthritis.jpg"
                  alt="Fight Back Against Knee Osteoarthritis"
                  className="img-fluid"
                />
              </div>
              <div className="discomfort-info">
                <p>
                  Imagine living with this constant pain. Your days filled with
                  discomfort, your nights restless from aching. Climbing stairs
                  feels like scaling a mountain. Simple tasks such as gardening,
                  shopping or even just a walk in the park become challenging
                  endeavors. Worse still, the pain medication can lead to
                  long-term dependency, and the possibility of surgery may
                  entail significant downtime, risks and an uncertain outcome.
                </p>
                <p>
                  For too many of our patients, knee osteoarthritis is a fact of
                  life, a daily struggle that significantly reduces their
                  mobility, comfort, and enjoyment of life. Every step becomes
                  an agonising experience, each movement of the joint a reminder
                  of the deterioration happening within. The condition can be
                  debilitating, impeding work, social, and family life.
                  Traditional treatments – pain medication, physical therapy,
                  even surgery – are often just temporary solutions are often
                  short lived solutions with surgery being the final and most
                  drastic step.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relief-main">
        <div className="container text-center">
          <h2>
            What If You Could Obtain Long Lasting Relief For 3 Years or More?
          </h2>
        </div>
      </section>

      <section className="polyacrylamide pt pb ">
        <div className="container">
          <div className="discomfortsection-guidance">
            <div className="text-center">
              <span>Relief from knee arthritis</span>
              <h2>
                Introducing ARTHROSAMID: An intra-articular polyacrylamide
                hydrogel (IPAAG)
              </h2>
            </div>
            <div className="discomfort-main d-flex align-items-center align-items-md-start align-items-xl-center">
              <div className="discomfort-pic">
                <img
                  src="images/arthrosamid/groundbreaking.jpg"
                  alt="Introducing ARTHROSAMID: An intra-articular polyacrylamide hydrogel (IPAAG)"
                  className="img-fluid"
                />
              </div>
              <div className="discomfort-info">
                <p>
                  We’re excited to introduce a groundbreaking knee
                  osteoarthritis injection treatment now available at our
                  clinic. We one of only a select few clinics in the North West
                  offering a new long acting treatment for knee pain caused by
                  Osteoarthritis. ARTHROSAMID® is a new type of treatment for
                  knee osteoarthritis that offers patients an effective
                  alternative to the currently available therapies. Based on an
                  innovative non-biodegradable hydrogel technology, ARTHROSAMID®
                  is 2.5% cross-linked polyacrylamide and 97.5% water. When
                  injected into the knee ARTHROSAMID® cushions the joint and
                  reduces pain, providing safe and sustained relief, all with
                  one injection. 
                </p>
                <p>
                  This injection is minimally invasive and is performed in the
                  comfort of our clinic. Most importantly, it has a rapid
                  recovery period and you can often return to your daily
                  activities immediately. Embrace a future without the
                  constraints of knee osteoarthritis, a life where you’re back
                  in control.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="arthrosamid-work pt pb">
        <div className="container">
          <div className="discomfortsection-guidance">
            <div className="text-center">
              <span>Relief from knee arthritis</span>
              <h2>How Does Arthrosamid Work?</h2>
            </div>
            <div className="discomfort-main d-flex flex-column text-center">
              <div className="arthrosamid-pic">
                <img
                  src="images/arthrosamid/arthrosamidwork.jpg"
                  alt="How Does Arthrosamid Work"
                  className="img-fluid"
                />
              </div>
              <div className="arthrosamid-info">
                <p>
                  ARTHROSAMID® is an intra-articular polyacrylamide hydrogel
                  injection (iPAAG) for the symptomatic treatment of knee
                  osteoarthritis. Think of it as a gel-based solution, kind of
                  like a cushion that helps ease your knee pain.
                </p>
                <p>
                  When we inject ARTHROSAMID® into your knee, it works by
                  improving the natural ‘oil’ in your knee joint, which has
                  become less effective due to osteoarthritis. This oil, called
                  synovial fluid, is crucial because it acts as a lubricant and
                  provides cushioning for your knee. By enhancing this fluid,
                  Arthrosamid® helps to make your joint move more smoothly and
                  comfortably.
                </p>
                <p>
                  But that’s not all, ARTHROSAMID® also settles into the inner
                  lining of your knee joint, creating an additional layer of
                  cushioning. It’s like adding an extra pillow inside your knee
                  to alleviate the impact from walking and other activities.
                </p>
                <p>
                  The best part? When we administer this injection, we use
                  ultrasound guidance. This means we can see exactly where the
                  injection is going in real time, ensuring it’s placed
                  precisely where it will do the most good. It’s like using a
                  GPS for your knee, giving you the peace of mind that the
                  treatment is targeted right at the source of your pain.
                </p>
                <p>
                  It can <strong>reduce your pain, decrease stiffness,</strong> and <strong>help movement.</strong> It has been shown to be
                  safe and can give <strong>long-acting pain relief, improving your
                  quality of life.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="howeffective-work pt pb">
        <div className="container">
          <div className="discomfortsection-guidance">
            <div className="text-center">
              <span>Relief from knee arthritis</span>
              <h2>How Effective is Arthrosamid?</h2>
            </div>
            <div className="discomfort-main d-flex flex-column text-center">
              <div className="arthrosamid-pic">
                <img
                  src="images/arthrosamid/responding.png"
                  alt="How Effective is Arthrosamid?"
                  className="img-fluid"
                />
              </div>
             
            </div>
          </div>
        </div>
      </section>
      <section className="responseunder pt80 pb80">
        <div className="container">
              <div className="text-center">           
                <h3>"80% response with the under 70 year olds"</h3>
              </div>                          
          </div>        
      </section>

      <UltraSoundguide/>
    <LongTermFreedom/>
      <ResourcesInformation 
      title="Want to know more?"
      content={
        <>
          We have more information and resources if you would like to find out more about the treatment. Click below to read the Arthrosamid          
          Brochure and Patient Information and Find out how Arthrosamid can take you one step closer to getting back to normal activities.
        </>
      }
      LinkOne="ARTHROSAMID Broschure"
      pdfOne="../images/arthrosamid/Patient_Brochure-with-3-year-IDA-Graph_12042023-Digital.pdf"
      LinkTwo="ARTHROSAMID Broschure"
      pdfTwo="../images/arthrosamid/Arthrosamid-Patient-Leaflet-3-Year-IDA-follow-up-12042023-Digital.pdf"
      />
      <section className="mt">
      <div className="container">
        <div className="iframvideo">
        <iframe src="https://www.youtube.com/embed/5tuj3hOF4RA?si=T7UqWrr6FHcVCyAV&amp;controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        </div>
        
      </section>
      <AppointmentBooking />  
      <div className="mt">
      <Testimonial />
      </div>    
      <YouLoveForm />
    </PageLayout>
  );
}
