import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import "./style.css";
import useScrollToTop from "../../service/UseScrollToTop";

export default function HeroSectionData({herotitleone, herotitletwo, herocontent, herobtn, imagespng}) {
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = useScrollToTop();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 300);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <section className="hero-section-main">
      <div className="unwanted-skin">
        <div className="container">
        <div className="hero-info">
            {herotitleone && <h1>{herotitleone}</h1>}
            {herotitletwo && <h2>{herotitletwo}</h2>}
            {herocontent && <p>{herocontent}</p>}
          </div>
          <div className="book-consultation">
           {herobtn &&  
           <Link to="https://soneh-medical.uk2.cliniko.com/bookings?business_id=1553630126283957172&practitioner_id=1551701359156273513" target="_blank" className="btn" onClick={scrollToTop}>
            {herobtn}
            </Link>
            }

            <div className="guided-customers">
              <div className="customers-group">
                <img
                  src="../images/Home/customers1.png"
                  alt="customers"
                  className="img-fluid"
                />
                <img
                  src="../images/Home/customers2.png"
                  alt="customers"
                  className="img-fluid customersimg"
                />
                <img
                  src="../images/Home/customers3.png"
                  alt="customers"
                  className="img-fluid customersimg"
                />
              </div>
              {isVisible && 
              <p>
                <CountUp start={isVisible ? 0 : null} end={450} suffix="+" /> Happy Customers
              </p>
              }              
            </div>
          </div>
        </div>
      </div>
      <div className="HeroSlider">     
        <picture>        
         <img src={imagespng} alt={herotitleone} className="img-fluid" />
    </picture>
      </div>
    </section>
  );
}
