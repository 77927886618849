import React from "react";
import "./style.css";

export default function ClinicDirector() {
  return (
    <section className="clinic-director d-flex pt pb">                     
          <div className="director-pic">
            <img
              src="../images/Home/soul-mugerwa.png"
              alt="Dr. Soul Mugerwa"
            />
          </div>
          <div className="director-info">
          <div className="aesthetics-info">
            <strong>Meet our Clinic Director</strong>
          <h2>Dr. Soul Mugerwa</h2>
          <span>MBChB(hons), MRCGP, DFSEM (UK), PGDip Aesthetics</span>
          </div>
          <p>
          Dr Soul Mugerwa is a GP with specialist interest in Musculoskeletal Medicine and Minor Surgery. He has published multiple papers in reputable peer reviewed medical journals, is a GP Educator and Clinic Tutor for University of Liverpool Medical Students.
          </p>
          <p>He holds a Postgraduate Diploma in Musculoskeletal Medicine and is a Member of the Faculty of Sports and Exercise Medicine (UK). He has over 10 years experience in performing joint and soft tissue injections and minor surgical procedures.</p>        
        </div>
      
    </section>
  );
}

