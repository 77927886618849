import React from "react";
import { Link } from "react-router-dom";
import ArrowRignt from "../Icons/ArrowRignt";
import ArthrosamidInjection from "../Icons/ArthrosamidInjection";
import PlateletRich from "../Icons/PlateletRich";
import SteroidInjectionIcon from "../Icons/SteroidInjectionIcon";
import "./style.css";
import useScrollToTop from "../../service/UseScrollToTop";

export default function NonSurgical() {
  const scrollToTop = useScrollToTop();
  return (
    <section className="non-surgical pt">
      <div className="container">
        <div className="text-center fasater-title">
          <h2>Novel Non-Surgical Treatments That Get You Moving Again</h2>
          <p>
            We providing world class Treatment that helps you recover 10x
            Fasater than normal surgery
          </p>
        </div>

        <div className="non-surgical-main d-flex flex-wrap">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="non-surgical-box">
                <div className="non-surgical-pic">
                  <SteroidInjectionIcon />
                </div>
                <div className="non-surgical-info">
                  <strong>Steroid Injection</strong>

                  <p>
                    Ideal for acute pain and inflammation, providing rapid
                    relief of up to 3 months or longer
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <Link to="/steroid-injection" onClick={scrollToTop} className="btn">
                      Learn More <ArrowRignt />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="non-surgical-box">
                <div className="non-surgical-pic">
                  <ArthrosamidInjection />
                </div>
                <div className="non-surgical-info">
                  <strong>Arthrosamid Injection</strong>
                  <p>
                    A game-changing synthetic gel that provides long-lasting
                    pain relief of 3 years or more for osteoarthritis.
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <Link to="/arthrosamid" onClick={scrollToTop} className="btn">
                      Learn More <ArrowRignt />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="non-surgical-box">
                <div className="non-surgical-pic">
                  <PlateletRich />
                </div>
                <div className="non-surgical-info">
                  <strong>Platelet Rich Fibrin Injection</strong>
                  <p>
                    A natural treatment using your body’s own healing factors to
                    promote recovery.
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <Link onClick={scrollToTop} to="/platelet-rich-fibrin" className="btn">
                      Learn More <ArrowRignt />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-between">
            <div className="non-surgical-box non-surgical-box02">
              <div className="non-surgical-pic">
                <img
                  src="../images/Home/ostenil.png"
                  className="img-fluid"
                  alt="Ostenil Plus Injection"
                />
              </div>
              <div className="non-surgical-info">
                <strong>Ostenil Plus Injection</strong>
                <p>
                  Helps to restore the natural lubrication in your joints,
                  improving mobility for up to 6 months.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <Link onClick={scrollToTop} to="/ostenil-plus" className="btn">
                    Learn More <ArrowRignt />
                  </Link>
                </div>
              </div>
            </div>

            <div className="non-surgical-box non-surgical-box02">
              <div className="non-surgical-pic">
                <img
                  src="../images/Home/durolane.png"
                  className="img-fluid"
                  alt="Durolane Injection"
                />
              </div>
              <div className="non-surgical-info">
                <strong>Durolane Injection</strong>
                <p>
                  A single injection treatment that can provide osteoarthritis
                  pain relief for up to 12 months.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <Link onClick={scrollToTop} to="/durolane" className="btn">
                    Learn More <ArrowRignt />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
