import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter } from 'react-router-dom';
import '../src/assest/css/style.css';
import MouseMovement from "./component/MouseMovement";
import NavigationRouting from "./component/NavigationRouting";
import { useRef } from "react";
import BgIcons from "./pages/BgIcons/BgIcons";

function App() {
  const contentRef = useRef(null);
  const bgIconsRef = useRef(null); // Create a ref for BgIcons component

  return (
    <div className="App" ref={contentRef}>
      <BrowserRouter>
        <NavigationRouting />
        <MouseMovement contentRef={contentRef} onBallHover={(x, y) => bgIconsRef.current.handleBallHover(x, y)} />
        <BgIcons ref={bgIconsRef} /> {/* Pass ref to BgIcons */}
      </BrowserRouter>
    </div>
  );
}

export default App;
