import React from "react";

export default function PlateletRich() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80px"
      height="80px"
      viewBox="0 0 112 108"
      fill="none"
    >
      <path
        d="M7.77931 95.3684C7.47002 95.3684 7.12207 95.2524 6.85144 95.0204C6.34884 94.5178 6.34884 93.7446 6.85144 93.242L18.4886 81.6049C18.9912 81.1023 19.7644 81.1023 20.267 81.6049C20.7696 82.1075 20.7696 82.8807 20.267 83.3833L8.62987 95.0204C8.47522 95.2524 8.0886 95.3684 7.77931 95.3684Z"
        fill="black"
      />
      <path
        d="M13.4239 101.013C13.1146 101.013 12.7667 100.897 12.496 100.665C11.9934 100.162 11.9934 99.3892 12.496 98.8866L24.1332 87.2495C24.6358 86.7469 25.409 86.7469 25.9116 87.2495C26.4142 87.7521 26.4142 88.5253 25.9116 89.0279L14.2745 100.665C14.0812 100.897 13.8105 101.013 13.4239 101.013Z"
        fill="black"
      />
      <path
        d="M40.7963 85.1617C40.487 85.1617 40.139 85.0458 39.8684 84.8138L22.7413 67.6481C22.2387 67.1455 22.2387 66.3722 22.7413 65.8696C23.2439 65.367 24.0172 65.367 24.5198 65.8696L41.6468 82.9967C42.1494 83.4993 42.1494 84.2725 41.6468 84.7751C41.4149 85.0071 41.1056 85.1617 40.7963 85.1617Z"
        fill="black"
      />
      <path
        d="M52.0468 47.8147C51.7375 47.8147 51.3895 47.6987 51.1189 47.4667L47.0207 43.3686C46.5181 42.866 46.5181 42.0928 47.0207 41.5902C47.5233 41.0876 48.2966 41.0876 48.7992 41.5902L52.8973 45.6883C53.3999 46.1909 53.3999 46.9641 52.8973 47.4667C52.704 47.66 52.356 47.8147 52.0468 47.8147Z"
        fill="black"
      />
      <path
        d="M43.0773 53.9619C42.768 53.9619 42.4201 53.8459 42.1495 53.6139L39.4818 50.9463C38.9792 50.4437 38.9792 49.6704 39.4818 49.1678C39.9844 48.6652 40.7576 48.6652 41.2602 49.1678L43.9279 51.8355C44.4305 52.3381 44.4305 53.1113 43.9279 53.6139C43.7346 53.8459 43.4253 53.9619 43.0773 53.9619Z"
        fill="black"
      />
      <path
        d="M36.8915 62.9313C36.5822 62.9313 36.2342 62.8154 35.9636 62.5834L31.8655 58.4853C31.3629 57.9827 31.3629 57.2094 31.8655 56.7068C32.3681 56.2042 33.1413 56.2042 33.6439 56.7068L37.742 60.805C38.2446 61.3076 38.2446 62.0808 37.742 62.5834C37.5487 62.8154 37.2781 62.9313 36.8915 62.9313Z"
        fill="black"
      />
      <path
        d="M67.1635 32.7367C66.8542 32.7367 66.5063 32.6207 66.2356 32.3887L62.2148 28.2906C61.7122 27.788 61.7122 27.0148 62.2148 26.5122C62.7174 26.0096 63.4907 26.0096 63.9933 26.5122L68.0914 30.6103C68.594 31.1129 68.594 31.8861 68.0914 32.3887C67.8208 32.582 67.4728 32.7367 67.1635 32.7367Z"
        fill="black"
      />
      <path
        d="M58.194 38.8839C57.8847 38.8839 57.5368 38.7679 57.2662 38.5359L54.5985 35.8683C54.0959 35.3657 54.0959 34.5924 54.5985 34.0898C55.1011 33.5872 55.8743 33.5872 56.3769 34.0898L59.0446 36.7575C59.5472 37.2601 59.5472 38.0333 59.0446 38.5359C58.8513 38.7292 58.5033 38.8839 58.194 38.8839Z"
        fill="black"
      />
      <path
        d="M92.4096 16.4602C92.1003 16.4602 91.7523 16.3442 91.4817 16.1122C90.9791 15.6096 90.9791 14.8364 91.4817 14.3338L105.438 0.37695C105.941 -0.12565 106.714 -0.12565 107.217 0.37695C107.72 0.87955 107.72 1.65278 107.217 2.15538L93.2601 16.1122C93.0281 16.3442 92.7189 16.4602 92.4096 16.4602Z"
        fill="black"
      />
      <path
        d="M33.7986 96.8762C32.7161 96.8762 31.6335 96.4896 30.783 95.639L11.9935 76.8495C10.331 75.1871 10.331 72.4421 11.9935 70.7797C13.6559 69.1172 16.4009 69.1172 18.0633 70.7797L36.8529 89.6078C37.6647 90.4197 38.09 91.5022 38.09 92.6234C38.09 93.7446 37.6647 94.8271 36.8529 95.639C36.0023 96.4123 34.8811 96.8762 33.7986 96.8762ZM14.9704 72.0168C14.5451 72.0168 14.0812 72.2101 13.7719 72.5581C13.076 73.254 13.076 74.3365 13.7719 75.0324L32.5614 93.8219C32.8707 94.1312 33.3347 94.3632 33.7599 94.3632C34.2625 94.3632 34.6878 94.1699 34.9584 93.8219C35.2677 93.5127 35.4997 93.0487 35.4997 92.6234C35.4997 92.1208 35.3064 91.6956 34.9584 91.4249L16.1689 72.6354C15.8983 72.1715 15.4344 72.0168 14.9704 72.0168Z"
        fill="black"
      />
      <path
        d="M12.6121 107.469C11.5295 107.469 10.447 107.083 9.59646 106.232L1.28423 97.92C0.472337 97.1081 0.0470581 96.0256 0.0470581 94.9044C0.0470581 93.7833 0.472337 92.7007 1.28423 91.8888C2.94668 90.2264 5.69165 90.2264 7.3541 91.8888L15.6663 100.201C17.3288 101.864 17.3288 104.608 15.6663 106.271C14.8544 107.083 13.7332 107.469 12.6121 107.469ZM11.4136 104.454C12.1095 105.15 13.192 105.15 13.8879 104.454C14.1972 104.145 14.4292 103.681 14.4292 103.255C14.4292 102.753 14.2358 102.327 13.8879 102.057L5.61432 93.7446C4.91841 93.0487 3.83589 93.0487 3.13998 93.7446C2.83069 94.0539 2.59872 94.5178 2.59872 94.9431C2.59872 95.4457 2.79203 95.871 3.13998 96.1416L11.4136 104.454Z"
        fill="black"
      />
      <path
        d="M35.0744 90.845C34.7652 90.845 34.4172 90.729 34.1466 90.497C33.644 89.9944 33.644 89.2212 34.1466 88.7186L87.2289 35.6363C88.1954 34.6697 88.7367 33.3553 88.7367 32.0021V23.7672C88.7367 23.4579 88.8527 23.1099 89.0846 22.8393L91.4817 20.4423C92.1003 19.8237 92.4095 19.0505 92.4095 18.2386C92.4095 17.4267 92.1003 16.6148 91.4817 16.0349C90.8631 15.4163 90.0898 15.107 89.278 15.107C88.4661 15.107 87.6542 15.4163 87.0742 16.0349L84.6772 18.4319C84.4453 18.6639 84.0973 18.7798 83.7494 18.7798H75.5144C74.1226 18.7798 72.8468 19.3598 71.8803 20.2876L63.8773 28.2133C63.8386 28.2519 63.8386 28.2519 63.8386 28.2519L18.8366 73.37C18.334 73.8726 17.5608 73.8726 17.0582 73.37C16.5556 72.8674 16.5556 72.0941 17.0582 71.5915L62.1762 26.4735C62.2149 26.4348 62.2149 26.4348 62.2149 26.4348L70.1405 18.5092C71.6096 17.0401 73.5427 16.3055 75.5918 16.3055H83.3241L85.3345 14.2951C86.3783 13.2512 87.8475 12.6327 89.3166 12.6327C90.7858 12.6327 92.2549 13.2512 93.2988 14.2951C94.3426 15.339 94.9612 16.8081 94.9612 18.2772C94.9612 19.7464 94.3426 21.2155 93.2988 22.2594L91.2884 24.2698V32.0021C91.2884 34.0512 90.4765 35.9842 89.0846 37.4534L35.9637 90.497C35.7317 90.729 35.4224 90.845 35.0744 90.845Z"
        fill="black"
      />
      <path
        d="M90.0125 25.0044C89.7032 25.0044 89.3552 24.8884 89.0846 24.6564L82.8601 18.4319C82.3575 17.9293 82.3575 17.1561 82.8601 16.6535C83.3627 16.1509 84.1359 16.1509 84.6385 16.6535L90.8631 22.878C91.3657 23.3806 91.3657 24.1538 90.8631 24.6564C90.6697 24.8884 90.3218 25.0044 90.0125 25.0044Z"
        fill="black"
      />
      <path
        d="M106.328 27.6334C104.279 27.6334 102.461 26.5895 101.456 24.8111C100.451 23.0326 100.451 20.9449 101.456 19.1278L105.245 12.594C105.477 12.2074 105.902 11.9754 106.328 11.9754C106.753 11.9754 107.217 12.2074 107.41 12.594L111.199 19.1665C112.204 20.9449 112.204 23.0326 111.199 24.8497C110.194 26.5895 108.377 27.6334 106.328 27.6334ZM106.328 15.7256L103.621 20.4036C103.041 21.3702 103.041 22.53 103.621 23.4966C104.201 24.4631 105.206 25.0817 106.328 25.0817C107.449 25.0817 108.454 24.5018 109.034 23.4966C109.614 22.4914 109.614 21.3702 109.034 20.4036L106.328 15.7256Z"
        fill="black"
      />
    </svg>
  );
}
