import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useScrollToTop from "../../service/UseScrollToTop";
import Email from "../Icons/Email";
import Loaction from "../Icons/Loaction";
import Phone from "../Icons/Phone";
import TimeWatch from "../Icons/TimeWatch";
import "./style.css";

export default function Footer() {
  const scrollToTop = useScrollToTop();
  const currentYear = new Date().getFullYear();
  const [active, setActive] = useState(false);

  const toggleMenu = () => {
    setActive(!active);
  };
  useEffect(() => {    
    if (active) {
      document.body.classList.add("blurbody");
    } else {
      document.body.classList.remove("blurbody");
    }
    return () => {
      document.body.classList.remove("blurbody");
    };
  }, [active]);

  return (
    <footer>
      <div className="container">
        <div className="footer-first d-flex justify-content-between align-items-center">
          <div className="footerinphone">
            <Link to="/" className="footer-logo" onClick={scrollToTop}>
              <img
                src="../images/logo.png"
                alt="Get Back To Doing What you Love"
                className="img-fluid"
              />
            </Link>

            <div className="footer-code showphone d-flex flex-column text-center">
              <img
                src="../images/qr-code.png"
                alt="scan & book your appointment now"
                className="img-fluid"
              />
              <strong>scan & book your appointment now</strong>
            </div>
          </div>

          <div className="footer-box d-flex align-items-center">
            <div className="footer-info d-flex flex-column">
              <strong>
                Have Questions ? Call Us for a Free Consultation ?
              </strong>
              <p>
                If you have any questions or confused about any treatment call
                us for a consultation.
              </p>
            </div>
            <Link to="/faq" onClick={scrollToTop} className="btn">
              Get Started
            </Link>
          </div>

          <div className="footer-code hidephone showpg d-flex flex-column text-center">
            <img
              src="../images/qr-code.png"
              alt="scan & book your appointment now"
              className="img-fluid"
            />
            <strong>scan & book your appointment now</strong>
          </div>
        </div>
      </div>

      <div className="footer-second">
        <div className="container">
          <ul>
            <li>
              <a href="tel:01512037840">
                <span>
                  <Phone />
                </span>{" "}
                <p>0151 203 7840</p>
              </a>
            </li>
            <li>
              <a href="mailto:info@skinjointclinic.co.uk">
                <span>
                  <Email />
                </span>{" "}
                <p>info@skinjointclinic.co.uk</p>
              </a>
            </li>
            <li>
              <a
                href="https://maps.app.goo.gl/PyrQ8SdoUXzGyVyc6"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>
                  <Loaction />
                </span>{" "}
                <p>
                  Higher Bebington Health Centre, Higher Bebington, Wirral, CH63
                  2LR.
                </p>
              </a>
            </li>
            <li>
              <Link to="#">
                <span>
                  <TimeWatch />
                </span>{" "}
                <p>Mon - Fri 09:00 AM – 08.00 PM</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="copyright">
        <div className="container">
          <p>©{currentYear} Skin and Joint Clinic UK. All Rights Reserved.</p>
        </div>
      </div>
      <button className={`fixed-btn ${active ? 'active' : ''}`} onClick={toggleMenu}>
        <img src="./images/booking.svg" alt="Booking Now" />
        <ul>
          <li>
            <a href="tel:01512037840" className="btn btn02">Call Now</a>
          </li>
          <li>
            <a href="https://soneh-medical.uk2.cliniko.com/bookings?business_id=1553630126283957172&practitioner_id=1551701359156273513" target="_blank" className="btn">
              Book Now
            </a>
          </li>
        </ul>
      </button>      
    </footer>
  );
}
