import React from 'react'
import HeroSectionData from '../../component/HeroSection/HeroData'
import PageLayout from '../../component/PageLayout'
import Testimonial from '../../component/Testimonial'
import YouLoveForm from '../../component/YouLove'
import "./style.css"

export default function PlateletRichFibrin() {
  return (
    <PageLayout>
        <HeroSectionData
        herotitleone="Skin & Joint Injection Clinic"
        herotitletwo="PLATELET RICH FIBRIN"
        herocontent="A natural treatment using growth factors and stem cells to treat pain from tendonitis and osteorthritis and promote healing."
        herobtn="Book Your Consultation"
        imagespng="../images/platelet-rich-fibrin/platelet-rich-fibrin.jpeg"
      />  
    <section className="discomfortsection pt pb">
        <div className="container">
          <div className="discomfortsection-guidance">
              <div className="text-center">
                <span>
                Platelet rich Fibrin
                </span>
                <h2>Arthritis and Tendonitis can be a real pain!</h2>
              </div>
              <p>
              Are you one of the millions suffering from joint pain or tendon discomfort? Whether it’s a persistent ache in your knee, stiffness in your shoulder, or knee or elbow tendonitis, these issues are more than mere inconveniences. They’re barriers that hinder your everyday life, and they’re problems that deserve attention. From arthritis to sports injuries, the reasons behind joint and soft tissue pain are diverse. But the result is often the same: a diminished quality of life, where simple tasks become challenges. 
              </p>
              <div className='discomfort-main d-flex align-items-md-start align-items-lg-center'>
              <div className='discomfort-pic'>
              <img
                src="images/platelet-rich-fibrin/physical-discomfort.jpeg"
                alt="Arthritis and Tendonitis can be a real pain!"
                className="img-fluid"
              />
              </div>
              <div className='discomfort-info'>
              <p>
              Joint and soft tissue pain isn’t just about physical discomfort. It’s about missing out on your favorite hobbies, struggling with daily routines, and feeling held back by your own body. Whether it’s playing with your children, enjoying a walk in the park, or simply getting a good night’s sleep, pain can rob you of life’s pleasures.
              </p>
              <p>Chronic pain isn’t only a physical issue; it takes an emotional toll as well. The constant struggle with pain can lead to feelings of frustration, anxiety, or even depression. It affects not just you but also those around you who see you suffer.</p>
              <p>You may have tried various remedies, from over-the-counter medications to physical therapy. While these solutions may offer temporary relief, they often fail to address the underlying cause of the problem. It’s a cycle of pain and temporary solutions that leaves you yearning for something more effective.</p>
              </div>
              
              
            </div>
          </div>
        </div>
      </section>


      <section className="introducingplatelet pt pb">
        <div className="container">
          <div className="discomfortsection-guidance">
              <div className="text-center">
                <span>
                Using your body’s power to heal 
                </span>
                <h2>Introducing Platelet Rich Fibrin</h2>
              </div>             
              <div className='discomfort-main d-flex align-items-center'>
              <div className='discomfort-pic'>
              <img
                src="images/platelet-rich-fibrin/groundbreaking.jpeg"
                alt="Introducing Platelet Rich Fibrin"
                className="img-fluid"
              />
              </div>
              <div className='discomfort-info'>
              <p>Platelet Rich Fibrin (PRF) is a groundbreaking medical innovation that harnesses your body’s natural healing properties. By concentrating platelets, stem cells and growth factors from your own blood, PRF injections can stimulate cellular regeneration and repair. By delivering a higher concentration of growth factors directly to an injured area. This can aid in the repair of soft tissues and joints.</p>
              </div>              
              
            </div>
          </div>
        </div>
      </section>

      <section className="doseprf pt80 pb80">
        <div className="container">
              <div className="text-center">           
                <h2>How Does PRF Work?</h2>
              </div>             
             <p>Once extracted from your blood, the PRF is carefully injected into the affected joint or soft tissue area. The concentrated growth factors begin to stimulate your body’s healing process, promoting new cell growth and reducing inflammation. It’s a natural way to heal, using your body’s own resources.</p>
          </div>        
      </section>

      <section className="step-process pt pb">
        <div className="container">
              <div className="text-center">           
                <h2>A Three Step Process</h2>
              </div>             
           <div className='step-process-main d-flex flex-wrap'>
            <div className='step-process-box'>
            <div className='step-process-pic'>
            <img
                src="images/platelet-rich-fibrin/step-process1.jpeg"
                alt="Blood is taken from your arm"
                className="img-fluid"
              />
              </div>
                <p><span>01</span> Blood is taken from your arm.</p>             
            </div>
            <div className='step-process-box'>
            <div className='step-process-pic'>
            <img
                src="images/platelet-rich-fibrin/step-process2.jpeg"
                alt="The blood is then centrifuged to create the PRF."
                className="img-fluid"
              />
              </div>
                <p><span>02</span> The blood is then centrifuged to create the PRF.</p>             
            </div>
            <div className='step-process-box'>
            <div className='step-process-pic'>
            <img
                src="images/platelet-rich-fibrin/step-process3.jpeg"
                alt="The PRF is the injected with ultrasound guidance."
                className="img-fluid"
              />
              </div>
                <p><span>03</span> The PRF is the injected with ultrasound guidance.</p>              
            </div>
            </div>   

          </div>        
      </section>
      <section className="step-process bordertopprocess pt pb">
        <div className="container">
              <div className="text-center">           
                <h2>Benefits of PRF</h2>
              </div>             
           <div className='step-process-main d-flex flex-wrap'>
            <div className='step-process-box'>
            <div className='step-process-pic'>
            <img
                src="images/platelet-rich-fibrin/step-process4.jpeg"
                alt="PRF accelerates the body’s natural healing process. This can help signficantly increase the healing and recovery time for injuries or tendonitis."
                className="img-fluid"
              />
              </div>
                <p>PRF accelerates the body’s natural healing process. This can help signficantly increase the healing and recovery time for injuries or tendonitis.</p>             
            </div>
            <div className='step-process-box'>
            <div className='step-process-pic'>
            <img
                src="images/platelet-rich-fibrin/step-process5.jpeg"
                alt="Many patients report significant pain relief after treatment. Whether its the pain of tendonitis or osteoarthritis, PRF could help reduce this."
                className="img-fluid"
              />
              </div>
                <p>Many patients report significant pain relief after treatment. Whether its the pain of tendonitis or osteoarthritis, PRF could help reduce this.</p>             
            </div>
            <div className='step-process-box'>
            <div className='step-process-pic'>
            <img
                src="images/platelet-rich-fibrin/step-process6.jpeg"
                alt="As PRF uses your own blood, the risk of allergic reactions or infections is minimised."
                className="img-fluid"
              />
              </div>
                <p>As PRF uses your own blood, the risk of allergic reactions or infections is minimised.</p>              
            </div>
            </div>   

          </div>        
      </section>

      <Testimonial/>     
      <YouLoveForm/>
        </PageLayout>
  )
}
