import { useEffect } from 'react';

const useScrollToTop = () => {
  const scrollToTop = () => {    
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {    
    window.scrollTo(0, 0);
  }, []);

  return scrollToTop;
};

export default useScrollToTop;
