import React from "react";
import AppointmentsData from "../../component/AppointmentBooking/AppointmentsData";
import HeroSectionData from "../../component/HeroSection/HeroData";
import PageLayout from "../../component/PageLayout";
import data from "../../component/RestoreLubrication/data";
import Testimonial from "../../component/Testimonial";
import UltraSoundguide from "../../component/UltraSoundguide";
import YouLoveForm from "../../component/YouLove";
import "./style.css";

export default function Durolane() {
  return (
    <PageLayout>
      <HeroSectionData
        herotitleone="Skin & Joint Injection Clinic"
        herotitletwo="DUROLANE"
        herocontent="A single-injection hyaluronic acid treatment for knee osteoarthritis providing up 12 months of relief."
        herobtn="Begin your journey to a pain-free life today."
        imagespng="../images/durolane/durolane.jpeg"
      />
      
      <section className="osteoarthritisnew durolanepage pt pb">
        <div className="container">
          <div className="discomfortsection-guidance">
            <div className="text-center">
              <h2>The Burden of Osteoarthritis</h2>
            </div>
            <div className="discomfort-main d-flex flex-sm-column flex-md-row align-items-md-start align-lg-items-center">
              <div className="discomfort-pic">
                <img
                  src="images/durolane/durolane1.jpeg"
                  alt="The Burden of Osteoarthritis"
                  className="img-fluid"
                />
              </div>
              <div className="discomfort-info">
                <p>
                  Millions of people are living with the painful and
                  debilitating symptoms of Osteoarthritis (OA). When you have
                  osteoarthritis of the knee, the hyaluronic acid breaks down
                  and becomes diluted. This reduces its natural elastic
                  properties, which can further degrade cartilage tissue and
                  cause increased joint pain. This triggers inflammation and
                  damage that causes pain and stiffness when you move your knee.
                </p>
                <p>
                  The chronic stiffness, swelling, and pain in the joints can
                  severely limit mobility and hinder the quality of life.
                  Osteoarthritis is not just a minor inconvenience; it’s a
                  progressive disease that can lead to a drastic reduction in
                  one’s ability to perform daily tasks. It affects not only the
                  physical well-being but also the mental health of the
                  sufferers. Current therapies may provide temporary relief but
                  often fail to address the underlying cause of the symptoms.
                  Furthermore, oral medications can have systemic side effects,
                  while surgical interventions are invasive and can require
                  prolonged recovery. The problem  is complex and often requires
                  a multimodal approach.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="introducingplatelet introducingburolane pt pb">
        <div className="container">
          <div className="discomfortsection-guidance">
            <div className="text-center">
              <span>
                DUROLANE is a natural, non-addictive, non-opioid therapy for
                osteoarthritis knee pain.
              </span>
              <h2>Introducing Durolane</h2>
            </div>
            <div className="discomfort-main d-flex flex-sm-column flex-md-row align-items-md-start align-items-lg-center">
              <div className="discomfort-pic">
                <img
                  src="images/durolane/introducing-durolane.png"
                  alt="Introducing Platelet Rich Fibrin"
                  className="img-fluid"
                />
              </div>
              <div className="discomfort-info">
                <p>
                  DUROLANE is a unique, single-injection hyaluronic acid therapy
                  that provides long-lasting pain relief from knee
                  osteoarthritis. Hyaluronic acid (HA) in the knee acts as a
                  lubricant and shock-absorber. 
                </p>
                <p>
                  The hyaluronic acid in DUROLANE has the same composition as
                  the natural hyaluronic acid your body produces, but it’s in a
                  highly concentrated formulation that’s been uniquely
                  stabilized to help resist the degradation caused by
                  osteoarthritis. DUROLANE is administered as a single-injection
                  directly into the knee. We perform this in our clinic with
                  ultrasound guidance for added precision ensuring it gets to
                  the right place. 
                </p>
                <p>
                  DUROLANE gives you freedom from knee osteoarthritis pain,
                  improving joint function and quality of life as early as 2
                  weeks after your treatment. and lasting 12 months or more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="restoreLu-brication recognized">
        <div className="container">
          <div className="text-center">
            <h2>Key Benefits</h2>
          </div>
          <div className="restoreLu-brication-main d-flex">
            {data.slice(-3).map((item, index) => {
              return (
                <div className="restoreLu-brication-box" key={item.id}>
                  <div className="restoreLu-brication-no">
                    {String(index + 1).padStart(2, "0")}
                  </div>
                  <div className="restoreLu-brication-info">
                    {item.icon}
                    <span>{item.title}</span>
                    <p>{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="doseprf pt80 pb80">
    <div className="container">
        <div className="mobility d-flex align-items-center flex-column flex-md-row">
         <h2>Is Durolane Right for Me?</h2>              
         <div className="mobility-box">         
         <p>You may be a good candidate for DUROLANE if you have been diagnosed with knee osteoarthritis and are:</p>
         <ol>
         <li>Not getting adequate pain relief from oral medications, physical therapy, or steroid injections.</li>
         <li>Not able to maintain regular joint movement because of growing knee stiffness and pain.</li>
         <li>Not engaging in the regular physical activities you enjoy because of a loss of knee strength or mobility.</li>
        </ol>    
      </div> 
      </div>        
      </div>
  </section>     
      <div className="medicationsmedications">
        <UltraSoundguide />
      </div>
      <AppointmentsData />
      <Testimonial />
      <YouLoveForm />
    </PageLayout>
  );
}
