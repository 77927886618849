import React from 'react'
import Appointment from '../Icons/Appointment'

export default function AppointmentsData() {
  return (
    <section className="your-journey pt pb">
        <div className="container">
          <div className="text-center fasater-title">
            <h2>Your Journey With Us</h2>
            <p>
              Pain shouldn’t put your life on hold! When you are in pain you
              want the best possible treatment in a timely and convenient
              matter. Our patients come first and thats why we ensure a seamless
              pathway from consultation to treatment.
            </p>
          </div>

          <div className="yourjourney-main d-flex">
            <div className="yourjourney-box">
              <div className="yourjourney-pic">
                <img
                  src="../images/Home/appointment1.png"
                  className="img-fluid"
                  alt="Appointment Booking"
                />
              </div>
              <div className="yourjourney-info">
                <h5>
                  <Appointment /> Appointment Booking
                </h5>
                <p>
                  Discover the ease of scheduling appointments with our
                  convenient online booking – your path to consult us when it
                  works best for you. Curious about a treatment? Don’t hesitate
                  to reach out! A dedicated member of our team is always ready
                  to respond to your questions through a simple phone call or
                  email. We can offer 
                  <strong>same day appointments</strong> giving you the
                  reassurance you can get the best treatment when you really
                  need it.
                </p>
              </div>
            </div>

            <div className="yourjourney-box">
              <div className="yourjourney-pic">
                <img
                  src="../images/ostenil-plus/appointment4.jpeg"
                  className="img-fluid"
                  alt="Consultation"
                />
              </div>
              <div className="yourjourney-info">
                <h5>
                  <Appointment /> Consultation
                </h5>
                <p>
                  Your journey towards relief starts with a comprehensive
                  consultation with our skilled doctor, who will attentively
                  hear your concerns, conduct a thorough examination, and guide
                  you towards the most beneficial course of action to alleviate
                  your symptoms.
                </p>
              </div>
            </div>

            <div className="yourjourney-box">
              <div className="yourjourney-pic">
                <img
                  src="../images/ostenil-plus/appointment5.jpeg"
                  className="img-fluid"
                  alt="Procedure and Aftercare"
                />
              </div>
              <div className="yourjourney-info">
                <h5>
                  <Appointment /> Procedure and Aftercare
                </h5>
                <p>
                  If suitable, we provide the <strong>convenience of carrying out your
                  ultrasound guided injection during the same visit as your
                  consultation.</strong> This streamlined approach not only ensures you
                  receive timely care, but also enhances your overall patient
                  experience. Rest assured, we’ll equip you with comprehensive
                  aftercare guidelines, and should you need us, follow-up
                  appointments are readily available, so you’ll never feel left
                  in the lurch.
                </p>
              </div>
            </div>
          </div>        
      </div>
      </section>
  )
}
