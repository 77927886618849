import React from "react";
import Appointment from "../Icons/Appointment";
import "./style.css";

export default function AppointmentBooking() {
  return (
    <section className="your-journey pt position-relative">
    
        <div className="container">
          <div className="text-center fasater-title">
            <h2>Your Journey With Us</h2>
            <p>
              We understand the anxiety that comes with surgical procedures, and
              that’s why we prioritize safety and comfort. Our minimally
              invasive techniques ensure that your procedure is virtually
              pain-free, with minimal scarring and quick recovery.
            </p>
          </div>

          <div className="yourjourney-main d-flex">
            <div className="yourjourney-box">
              <div className="yourjourney-pic">
                <img
                  src="../images/Home/appointment1.png"
                  className="img-fluid"
                  alt="Appointment Booking"
                />
              </div>
              <div className="yourjourney-info">
                <h5>
                  <Appointment /> Appointment Booking
                </h5>
                <p>
                  Discover the ease of scheduling appointments with our
                  convenient online booking – your path to consult us when it
                  works best for you. Curious about a treatment? Don’t hesitate
                  to reach out! A dedicated member of our team is always ready
                  to respond to your questions through a simple phone call or
                  email. We can offer 
                  <strong>same day appointments</strong> giving you the
                  reassurance you can get the best treatment when you really
                  need it.
                </p>
              </div>
            </div>

            <div className="yourjourney-box">
              <div className="yourjourney-pic">
                <img
                  src="../images/Home/appointment2.png"
                  className="img-fluid"
                  alt="Consultation"
                />
              </div>
              <div className="yourjourney-info">
                <h5>
                  <Appointment /> Consultation
                </h5>
                <p>
                  You will have a consultation with our doctor who will listen
                  to your concerns and examine the skin lesion. If safe to do
                  so, and you wish to proceed then the lesion can be removed at
                  the same appointment. 
                </p>
              </div>
            </div>

            <div className="yourjourney-box">
              <div className="yourjourney-pic">
                <img
                  src="../images/Home/appointment3.png"
                  className="img-fluid"
                  alt="Procedure and Aftercare"
                />
              </div>
              <div className="yourjourney-info">
                <h5>
                  <Appointment /> Procedure and Aftercare
                </h5>
                <p>
                  We routinely offer a follow up appointment within 1-2 weeks
                  for a wound check and stitch removal (where needed). This
                  gives you reassurance we are by your side to answer any
                  questions or concerns and manage any complications should they
                  arise. 
                </p>
              </div>
            </div>
          </div>
        </div>
    
    </section>
  );
}
