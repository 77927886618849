import React from 'react'
import AppointmentBooking from '../../component/AppointmentBooking'
import HeroSectionData from '../../component/HeroSection/HeroData'
import PageLayout from '../../component/PageLayout'
import Testimonial from '../../component/Testimonial'
import YouLoveForm from '../../component/YouLove'
import "./style.css"

export default function MinorSurgery() {
  return (
    <PageLayout>
    <HeroSectionData
    herotitleone="Skin & Joint Injection Clinic"
    herotitletwo="SKIN LESION REMOVAL"
    herocontent="Offering skin surgery for the removal of unwanted skin lesions."
    herobtn="Book Your Consultation"
    imagespng="../images/minor-surgery/skin-surgery.jpeg"
    />    
    <section className="confidence confidence02 d-flex pt pb">                     
          <div className="confidence-pic">
            <img
              src="../images/minor-surgery/skin-lesions.jpeg"
              alt="Are Skin Lesions Affecting Your Confidence?"
              className='img-fluid'
            />
          </div>
          <div className="confidence-info">
          <div className="removal-info">
            <strong>Skin Issues </strong>
            <h2>Are Skin Lesions Affecting Your Confidence?</h2>          
          </div>
          <p>
          Skin issues like lipomas, skin tags, cysts, and moles might seem harmless at first glance, but they can greatly impact how you feel about yourself. Whether they’re noticeable or hidden, these lesions can cause discomfort, anxiety, or even loss of confidence. Do you find yourself constantly covering up or feeling self-conscious about your skin?
          </p>
          <p>Removal of many skin lesions for cosmetic reasons is not covered by the NHS. Our services are ideal for people who do not qualify for NHS removal or those who prefer to avoid the xtensive waiting times for these procedures.</p>        
        </div>      
    </section>

    <section className="confidence treatmentpersonalised mb80 d-flex">                              
          <div className="confidence-info">
          <div className="removal-info">
            <strong>Your path to clear skin </strong>
            <h2>Surgical Removal for Skin Lesions</h2>          
          </div>
          <p>When you choose our services, you can expect high-quality treatment and personalised aftercare tailored to your unique needs, without having to wait. All of our procedures are performed in our purpose built surgical suite. No two patients are the same, and neither are skin lesions. Whether you’re dealing with lipomas, skin tags, cysts, or moles, our experienced surgeons will craft a personalised treatment plan to address your unique needs and goals. </p>
          <p>Quality care shouldn’t be out of reach. We offer various payment options to make your treatment as affordable as possible. Your path to clear and healthy skin is accessible with us.</p>        
        </div>      
    </section>

    <section className="confidence personalisedaftercare mb80 d-flex">                              
          <div className="confidence-info">
          <div className="removal-info">
            <strong>Your path to clear skin </strong>
            <h2>What Skin Lesions Can Be Removed</h2>          
          </div>
          <p style={{marginBottom:0}}>We remove a range of skin lesion from the body and face. An initial consultation is required by our Doctor to assess the area/lesion of concern. We can treat the following lesions:</p>
          <ul>
            <li>Skin tags</li>
            <li>Lipomas</li>
            <li>Cysts</li>
            <li>Seborrhoeic keratosis</li>
            <li>Non cancerous moles</li>
          </ul>
          <p>We only remove non cancerous lesions. If at your consultation it is felt that the lesion looks potentially cancerous then you will be referred back to your GP for onward referral. We also do not treat patient under the age of 18 years old. We do not provide treatment for warts or verrucas on the sole of the feet or the palms of the hands.</p>        
        </div>      
    </section>

    <section className='lesionlooks'>
      <div className="consultationsurgical">
      <h4>"We can perform the surgical procedure on the same day as your consultation"</h4>
      </div>
    </section>
    <div className="consultationtop">
    <AppointmentBooking/>
    </div>
    <div className="mt">
    <Testimonial/>
    </div>
    <YouLoveForm/> 
    </PageLayout>
  )
}
