const FaqsData = [

    {   
        id:"1",
        question: "Are there any areas you do not treat?", 
        answer: "We do not perform injections for the spine including the neck, lower back and sacroiliac joint." 
    },
    {   
        id:"2",
        question: "Is the procedure painful?", 
        answer: "The discomfort experienced during the procedure can vary from person to person. You may feel a slight pinch or pressure when the needle is inserted. The local anesthetic included in the injection usually helps in minimizing the pain. Our skilled clinicians do their utmost to ensure the procedure is as comfortable as possible." 
    },
    {   
        id:"3",
        question: "How long does the procedure take and is there a recovery period?", 
        answer: "The procedure itself usually takes about 15-30 minutes. As it’s a non-surgical treatment, the recovery period is minimal. Most patients can leave the clinic immediately after the procedure and return to their normal activities within 24 hours. We will provide you with specific aftercare instructions based on your individual needs." 
    },
    {   
        id:"4",
        question: "When can i expect relief from my symptoms?", 
        answer: "Many patients experience relief within a few days to a week after the injection, but the exact time frame can vary depending on the individual and the specific joint treated." 
    },
    {   
        id:"5",
        question: "Do i need a referral for this procedure?", 
        answer: "A referral is not required for the consultation and procedure. You can book an appointment with us directly at your earliest convenience." 
    },
    {   
        id:"6",
        question: "will my insurance cover the cost of the procedure?", 
        answer: "This will depend on your private health insurance provider. Most of our patients are self funding but it may be possible to claim the cost of the treatment afterwards if you have insurance cover. It is recommended that you first check with your provider to ensure that they will cover the costs." 
    },
    {   
        id:"7",
        question: "How long does the recovery take after surgery?", 
        answer: "Recovery time varies depending on the type and size of the lesion, as well as the procedure used. Most patients can resume normal activities within a few days, but you will be provided with personalised aftercare instructions to ensure a smooth and quick recovery." 
    },

    {   
        id:"8",
        question: "Is the surgery painful? What kind of anaesthesia is used?", 
        answer: "Your comfort is our priority. Most procedures are performed under local anesthesia, ensuring that you are comfortable and pain-free throughout the surgery." 
    },
    {   
        id:"9",
        question: "Will there be noticeable scarring after the procedure?", 
        answer: "Scarring is unavoidable, however we use minimally invasive techniques to minimise scarring. While some procedures may leave a small scar, we take great care to make them as inconspicuous as possible. Following your personalised aftercare plan will further aid in reducing the appearance of any scars." 
    },
    {   
        id:"10",
        question: "Will my insurance cover the cost of the surgery?", 
        answer: "The cost of treatment depends on various factors such as the type and size of the lesion. We strive to provide affordable care and offer different payment options. We encourage you to contact your insurance provider if you have private cover to discuss whether they will cover the cost." 
    },
    {   
        id:"11",
        question: "What are the potential side effects with steroid injections?", 
        answer: "While many people experience significant relief with steroid injections, there can be side effects. These may include temporary pain or swelling at the injection site, changes in skin color, infection, or in rare cases, weakening of the surrounding tissues. There is a risk that very frequent injections can potentially further damage cartilage or soften bone." 
    },
    {   
        id:"12",
        question: "How long will the effects of steroid injection last for?", 
        answer: "The effects of a steroid injection can vary from person to person. Some people experience relief for several weeks or even months and beyond, while others may find that the effects wear off more quickly. There is also the possibility of the injection not working. In general the expected duration is around 3 months." 
    },
    {   
        id:"13",
        question: "Will the injection hurt?", 
        answer: "You might feel some discomfort or pressure during the injection, but it’s usually over quickly. You’ll likely be in and out of the office within an hour, and the actual injection takes just a few minutes. Some people can develop some additional pain over 1-2 days after the injection which usually settles." 
    },
    {   
        id:"14",
        question: "How many PRF injections are needed?", 
        answer: "The number of injections and the timeline for treatment can vary depending on the individual’s condition and the severity of the problem. Typically, a treatment plan may involve one or more injections spread over several weeks. Each procedure usually takes around 30 minutes to an hour." 
    },
    {   
        id:"15",
        question: "When can I expect to see improvement in my condition after PRF injections", 
        answer: "Many patients report feeling improvement within a few weeks after the PRF injections, but the healing process can vary from person to person. It’s a gradual process where the PRF stimulates the body’s natural healing over time. Continued improvement may be noticed over the course of several months." 
    },
    {   
        id:"16",
        question: "Are PRF injections covered by insurance?", 
        answer: "Coverage for PRF injections may vary among different insurance providers and plans. It’s advisable to consult with your insurance company and the healthcare provider offering the PRF injections to understand the specific costs and any potential coverage." 
    },
    {   
        id:"17",
        question: "Can PRF injections be used in conjunction with other treatments?", 
        answer: "Yes, PRF injections can often be used alongside other therapies such as physical therapy or medications. In some cases, PRF may even enhance the effects of these other treatments by accelerating the healing process." 
    },
    {   
        id:"18",
        question: "How long do the effects of PRF injections last?", 
        answer: "The lasting effects of PRF injections can vary widely based on the injury or condition being treated, individual healing response, and overall health. Some patients experience relief for many months or even years." 
    },
    {   
        id:"19",
        question: "Are PRF injections suitable for everyone?", 
        answer: "While PRF injections can be beneficial for a wide variety of joint and soft tissue problems, they may not be suitable for everyone. Individuals with certain blood disorders, active infections, chronic diseases or anti platelet medication such as aspirin or clopidogrel may not be ideal candidates." 
    },
    {   
        id:"20",
        question: "Do i need to prepare for the injection?", 
        answer: "You may be provided with detailed instructions to follow before the injection. This can include guidance on avoiding certain medications, such as blood thinners or anti-inflammatory drugs, for a specific period before the procedure. Staying well-hydrated, as a blood draw is also part of the procedure." 
    },
    {   
        id:"21",
        question: "What joints can arthrosamid be used for?", 
        answer: "Studies with Arthrosamid® have only been carried out for the treatment of Knee osteoarthritis so at this time it is only indicated for use in the knee." 
    },
    {   
        id:"22",
        question: "How is arthrosamid different from other injections?", 
        answer: "Arthrosamid® is different from viscosupplement injections such as Hyaluronic Acid (HA), Platelet Rich Plasma (PRP) and regenerative stem cell therapy. Unlike these treatments, Arthrosamid® becomes an integrated part of the soft synovial tissue in the joint capsule and provides long-acting and sustain pain relief." 
    },
    {   
        id:"23",
        question: "How safe is arthrosamid?", 
        answer: "Arthrosamid® intra-articular (IA) polyacrylamide gel (iPAAG) is safe for its intended use treating knee OA pain symptoms. The most common adverse events (side effects) in clinical trials were injection-related mild to moderate pain and or mild swelling during the first weeks to months after the injection." 
    },
    {   
        id:"24",
        question: "How effective is arthrosamid?", 
        answer: "As Arthrosamid® works to cushion the joint, it can reduce your pain, decrease stiffness, and help movement. The hydrogel itself does not degrade and therefore it provides long-acting relief, improving your quality of life. In clinical trials, patients reported a significant reduction in their pain symptoms by week 4 after their injection and, unlike other injectable treatments, the average level of reduction was maintained at the 3-year follow-up period." 
    },
    {   
        id:"25",
        question: "Is arthrosamid for everyone?", 
        answer: "Arthrosamid® is approved for the symptomatic treatment of knee osteoarthritis so any patient with this condition may be suitable. However treatment with Arthrosamid® may not be suitable for everyone. Your doctor is the best person to advise you, but situations where you should not use Arthrosamid® (contraindications) include*:" ,
        listdata:[
            "If you have an infection at or near the injection site",
            "If you have haemophilia or take anticoagulant treatment",
            "If you have had knee replacement or knee arthroscopy within the past 6 months",            
        ],
       newans:"*This is not a full list of contraindications. Please refer to your doctor to see if Arthrosamid®can help you." 
    },
    {   
        id:"26",
        question: "How long will arthrosamid last?", 
        answer: "The hydrogel making up the injection will not degrade and therefore will provide long-lasting relief. So far, most patients have reported a significant reduction in their pain 4 weeks after their injection, and the average level of reduction was maintained at the 3-year follow-up period." 
    },
    {   
        id:"27",
        question: "Is my condition serious enough for arthrosamid?", 
        answer: "If you suffer with osteoarthritis pain and the pain is not controlled by simple pain killers and lifestyle modifications, then you could be suitable for Arthrosamid® treatment. To find out if Arthrosamid® is right for you, please consult your doctor." 
    },
    {   
        id:"28",
        question: "Can i have repeated treatments?", 
        answer: "The treatment could be repeated if required and advised by your doctor. It is not recommended to repeat the treatment within 6 months." 
    },
    {   
        id:"29",
        question: "Is there any preparation for the treatment?", 
        answer: "Yes. Antibiotics need to be taken 2-3 hours before the ultrasound guided injection of arthrosamid. These will be provided to you by the clinic." 
    },
    {   
        id:"30",
        question: "What joints can be treated?", 
        answer: "Ostenil is licensed for osteoarthritis and so can be used for all joints affected." 
    },
    {   
        id:"31",
        question: "Is ostenil plus suitable for vegetarians or vegans?", 
        answer: "Ostenil plus contains no blood, or animal derived products, or human donor products, so they are fine to be used by vegetarians or vegans." 
    },
    {   
        id:"32",
        question: "How soon will the pain go away after my injcetion?", 
        answer: "You may not notice any benefits immediately after the first injection, but you should gradually start to feel less pain and stiffness over the next few weeks." 
    },
    {   
        id:"33",
        question: "Is durolane treatment painful?", 
        answer: "Most patients report experiencing little pain or discomfort during the injection. Many doctors will apply a topical drug to numb the skin prior to giving the DUROLANE injection, which helps to minimize any pain or discomfort." ,
        newans:"Risks can include transient pain or swelling at the injection site"
    },
    {   
        id:"34",
        question: "How is the treatment performed?", 
        answer: "The entire procedure is performed in the doctor’s office and takes approximately 10 to 15 minutes. DUROLANE is administered by a single injection into the affected knee joint with ultrasound guidance. Your doctor may advise you to avoid strenuous physical activities for a few days after your injection."
    },
    {   
        id:"35",
        question: "Is my condition serious enough for this treatment?", 
        answer: "If you have knee osteoarthritis pain and the pain is not controlled by lifestyle changes or simple painkillers, you could be a candidate for DUROLANE treatment."
    },
    {   
        id:"36",
        question: "How is durolane different from a steroid injection?", 
        answer: "A steroid injection is used to reduce inflammation and pain. A steroid injection usually starts to work quicker than an injection of hyaluronic acid, but it doesn’t last as long. In clinical studies comparing steroid injections with DUROLANE treatment11 of patients with knee osteoarthritis, both treatments provided a reduction in knee pain and stiffness, but the benefits of the DUROLANE injection lasted several months longer and could be repeated without showing a decline in effect."
    },
    {   
        id:"37",
        question: "Are repeat treatments with durolane safe?", 
        answer: "Yes. DUROLANE is clinically proven to be safe, even following repeated injections."
    },
    {   
        id:"38",
        question: "Is there a reason why i could not get durolane treatment?", 
        answer: "You should not use DUROLANE if you have infections or skin disease at the injection site. DUROLANE has not been tested in pregnant or lactating women, or children."
    },
    
  ];
  export default FaqsData;