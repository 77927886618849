import React, { useState } from 'react'
import HeroSectionData from '../../component/HeroSection/HeroData'
import IconPlus from '../../component/Icons/IconPlus'
import PageLayout from '../../component/PageLayout'
import Testimonial from '../../component/Testimonial'
import YouLoveForm from '../../component/YouLove'
import FaqsData from './FaqsData'
import './style.css'

export default function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); 
    } else {
      setActiveIndex(index);
    }
  };

  // Split FAQ data into 3 columns
  const columnSize = Math.ceil(FaqsData.length / 3);
  const faqsColumn1 = FaqsData.slice(0, columnSize);
  const faqsColumn2 = FaqsData.slice(columnSize, columnSize * 2);
  const faqsColumn3 = FaqsData.slice(columnSize * 2);

  return (
    <PageLayout>
       <div className='faq-page'>
      <HeroSectionData
        herotitleone="Skin & Joint Injection Clinic"
        herotitletwo="FAQ"                
        imagespng="../images/faqbg.jpg"
        />       
      <section className='faq-main pt pb'>
          <div className='container'>
            <div className='faq-title text-center'>
              <h2>Frequently Asked Questions</h2>
              <p>Still have any questions? Contact our team via <a href='mailto:info@skinjointclinic.co.uk'>info@skinjointclinic.co.uk</a></p>
            </div>

            <div className='faq-box-main'>
              <div className='row'>
                {/* First Column */}
                <div className='col-12 col-md-4'>
                  {faqsColumn1.map((faqitem, index) => (
                    <div className={`faq-box ${activeIndex === index ? 'active' : ''}`} key={index}>
                      <div className="faq-question" onClick={() => toggleFAQ(index)}>
                        {faqitem.question}
                        <IconPlus />
                      </div>
                      <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                        <p>{faqitem.answer}</p>
                        {faqitem.listdata && faqitem.listdata.length > 0 && (
                          <ul>
                            {faqitem.listdata.map((item, idx) => (
                              <li key={idx}>{item}</li>
                            ))}
                          </ul>
                        )}
                        {faqitem.newans ? <p>{faqitem.newans}</p> : null}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Second Column */}
                <div className='col-12 col-md-4'>
                  {faqsColumn2.map((faqitem, index) => (
                    <div className={`faq-box ${activeIndex === index + columnSize ? 'active' : ''}`} key={index + columnSize}>
                      <div className="faq-question" onClick={() => toggleFAQ(index + columnSize)}>
                        {faqitem.question}
                        <IconPlus />
                      </div>
                      <div className={`faq-answer ${activeIndex === index + columnSize ? 'show' : ''}`}>
                        <p>{faqitem.answer}</p>
                        {faqitem.listdata && faqitem.listdata.length > 0 && (
                          <ul>
                            {faqitem.listdata.map((item, idx) => (
                              <li key={idx}>{item}</li>
                            ))}
                          </ul>
                        )}
                        {faqitem.newans ? <p>{faqitem.newans}</p> : null}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Third Column */}
                <div className='col-12 col-md-4'>
                  {faqsColumn3.map((faqitem, index) => (
                    <div className={`faq-box ${activeIndex === index + columnSize * 2 ? 'active' : ''}`} key={index + columnSize * 2}>
                      <div className="faq-question" onClick={() => toggleFAQ(index + columnSize * 2)}>
                        {faqitem.question}
                        <IconPlus />
                      </div>
                      <div className={`faq-answer ${activeIndex === index + columnSize * 2 ? 'show' : ''}`}>
                        <p>{faqitem.answer}</p>
                        {faqitem.listdata && faqitem.listdata.length > 0 && (
                          <ul>
                            {faqitem.listdata.map((item, idx) => (
                              <li key={idx}>{item}</li>
                            ))}
                          </ul>
                        )}
                        {faqitem.newans ? <p>{faqitem.newans}</p> : null}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

    <Testimonial/>
    <YouLoveForm/>
    </div> 
  </PageLayout>
  )
}
