const data = [
    {
        id: 1,
        title: 'Very Happy about the Results!',
        content: '"I had four enlarged, crusty warts on my face removed by Dr Soul Mugerwa. He was friendly and very professional during the initial consultation and wart removals on the same day. I had no infections or bleeding thereafter. After ten days, the skin on all four had healed very well and I am very happy."',
        pic:"../images/Home/testimonial1.png",
        name: 'Anthony M',        
    },
    {
        id: 2,
        title: 'Excellent Service!',
        content: '"I would highly recommend, one mole removed very successfully from eyelid, hardly left a mark. So impressed I went back and had a second one removed. Excellent all round experience."',
        pic:"../images/Home/testimonial2.png",
        name: 'P Snead',        
    },
    
    {
        id: 3,
        title: 'Can’t Find anything Better',
        content: '"Had two cysts removed from my scalp by Dr.Soul. Excellent, painless surgery carried out in a totally professional and friendly manner.I would totally recommend anyone requiring such a procedure to speak to Dr. Soul. Thanks once again."',
        pic:"../images/Home/testimonial3.png",
        name: 'P Dowling',        
    },
];

export default data;
